import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {TempStorage} from '../../temp_storage';
import firebase from 'firebase';
import * as $ from "jquery";



@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.css']
})
export class CompanyHeaderComponent implements OnInit {

  currentCompany = '';
  name=localStorage.getItem('name');
  phone = localStorage.getItem('customer_phone');
  is_login = localStorage.getItem('access_token')?true:false;

  //console.log(localStorageData)
  curruntCompanyData : any = [];
  constructor(private route: ActivatedRoute, public router: Router, public temp_storage:TempStorage) {
   }

  ngOnInit() {
    
      console.log("pp",this.temp_storage);
      $('#left-menu').on('click', function () {
        $('body').toggleClass('sidebar-left-close');
    });
  }

  getCompanyData(key){
    /*this.localStorageData = JSON.parse(localStorage.getItem('company_name')); 
      //console.log(this.localStorageData);
      //console.log(this.localStorageData.company_3);
    
    let splitted = this.router.url.split("/", 3);
    //console.log(splitted);
    this.currentCompany = splitted['2'];
    console.log(this.currentCompany);
    this.curruntCompanyData = this.localStorageData['company_'+this.currentCompany];
    console.log(this.curruntCompanyData);
    //console.log(this.currentCompany, this.curruntCompanyData);
    
    console.log(this.curruntCompanyData[key]); 
    return this.curruntCompanyData[key];*/
  
  }

  
  Logout(){
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  logout(){
    firebase.auth().signOut();
    this.temp_storage.is_login=false;
    localStorage.clear();
    this.router.navigate(['/']);
  }


}
