import {Injectable} from '@angular/core';

export class TempStorage {
   
    isWhiteBackground: boolean = false;
    is_login: boolean=false;
    loading: boolean=false;
    name: String = "";
    first_name: String="";
    last_name: String="";
    customer_phone: String;
    is_history_product:boolean = true;
    is_detail_product:boolean = false;
    firebase : any =  {
   	  apiKey: "AIzaSyCFISTvBSiMQ3QwtPD5H2_MJ6Bc_0wE8HM",
      authDomain: "trusttags.in",
      databaseURL: "https://trusttags-business-sb.firebaseio.com",
      projectId: "trusttags-business-sb",
      storageBucket: "trusttags-business-sb.appspot.com",
      messagingSenderId: "475084659914"
    }
    public constructor() {
   }     

}
