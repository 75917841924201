 <div style="padding-top:55px; min-height: 100%;" [ngClass]="{'bgimg': !temp_storage.isWhiteBackground}">

   <!-- <app-company-header *ngIf="temp_storage.is_login == true"></app-company-header> -->
   <!-- <div class="loading-overlay" *ngIf="loading">
    Loading<span class="d">.</span><span class="d d-2">.</span><span class="d d-3">.</span>
  </div> -->
   <div class="loader justify-content-center ">
     <div class="align-self-center text-center">
       <!-- <div class="logo-img-loader">
              <img src="assets/images/141.png" alt="" class="logo-image" style="border-radius: 25px;">
               <img src="assets/images/logo-150.png" alt="" class="logo-bg-image"> 
          </div> -->
     </div>
   </div>
 
   <app-company-header *ngIf="temp_storage.is_login==true"></app-company-header>

   <div [ngClass]="{'main-page':temp_storage.is_login==true}"  >
    <router-outlet></router-outlet>
   </div>
   <app-sidebar *ngIf="temp_storage.is_login"></app-sidebar>
   <div class="bottom-tabs" style="z-index: 10;background: transparent;">
    <app-tabs-line  *ngIf="temp_storage.is_login"></app-tabs-line>
  </div>
   <!--<app-footer  *ngIf="temp_storage.is_login"></app-footer>-->
 </div>

 <!--<div style="margin-top: 100px;">
    <router-outlet *ngIf="!isCompanyHeader"></router-outlet>
 </div>-->