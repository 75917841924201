<div class="loader_wrapper" *ngIf="loader"><div class="batch_loader" ></div></div>

		<div class="container-fluid bg-light-opac">
            <div class="row">
                <div class="container main-container">
                    <div class="row align-items-center">
                        <div class="col mt-4 mb-2">
                            <h2 class="content-color-primary page-title">Report</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<div class="container mt-4 text-center">
    <div class="card">
        <div class="card-body">
            <ng-wizard [config]="config" (stepChanged)="stepChanged($event)">
                <ng-wizard-step [title]="'Step 1'" [description]="'Camera 1'" [canEnter]="step1Enter" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
                    <div class="row">
                        <div class="col-12 col-lg-4"></div>
                        <div class="col-12 col-lg-4">
                            <div class="card mb-4 border border-primary mt-4">
                                <div class="card-body">
                                    <h4>Click Front of Pack</h4>
                                    <div class="media text-center flex-column">
                                        <div class="media-body w-100">
                                            <i class="far fa-file" style="font-size: 40px;color: cornflowerblue;"></i>
                                        </div>
                                        <label class="mt-3 text-center" *ngIf="frontImage">{{frontImage.name}}</label>
                                        <label for="file-input">
                                            <div class="icon-rounded btn btn-primary mx-auto mt-3">
                                                <i class="material-icons text-white">cloud_upload</i> Upload
                                            </div>
                                        </label>
                                        <input type="file" id="file-input" name="frontImage" accept="image/png, image/jpeg, image/jpg" capture="user" (change)="fileChange(1,$event)" style="display: none;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4"></div>
                    </div>
                </ng-wizard-step>

                <ng-wizard-step [title]="'Step 2'" [description]="'Camera 2'" [canEnter]="step2Enter" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
                    <div class="row">
                        <div class="col-12 col-lg-4"></div>
                        <div class="col-12 col-lg-4">
                            <div class="card mb-4 border border-primary mt-4">
                                <div class="card-body">
                                    <h4>Click Back of Pack</h4>
                                    <div class="media text-center flex-column">
                                        <div class="media-body w-100">
                                            <i class="far fa-file" style="font-size: 40px;color: cornflowerblue;"></i>
                                        </div>
                                        <label class="mt-3 text-center" *ngIf="backImage">{{backImage.name}}</label>
                                        <label for="file-input1">
                                            <div class="icon-rounded btn btn-primary mx-auto mt-3">
                                                <i class="material-icons text-white">cloud_upload</i> Upload
                                            </div>
                                        </label>
                                        <input type="file" id="file-input1" name="backImage" accept="image/png, image/jpeg, image/jpg" capture="user" (change)="fileChange(2,$event)" style="display: none;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4"></div>
                    </div>
                </ng-wizard-step>

                <ng-wizard-step [title]="'Step 3'" [description]="'Camera 3'" [canEnter]="step3Enter" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
                    <div class="row">
                        <div class="col-12 col-lg-4"></div>
                        <div class="col-12 col-lg-4">
                            <div class="card mb-4 border border-primary mt-4">
                                <div class="card-body">
                                    <h4>Click of Purchased Bill</h4>
                                    <div class="media text-center flex-column">
                                        <div class="media-body w-100">
                                            <i class="far fa-file" style="font-size: 40px;color: cornflowerblue;"></i>
                                        </div>
                                        <label class="mt-3 text-center" *ngIf="purchasedBill">{{purchasedBill.name}}</label>
                                        <label for="file-input2">
                                            <div class="icon-rounded btn btn-primary mx-auto mt-3">
                                                <i class="material-icons text-white">cloud_upload</i> Upload
                                            </div>
                                        </label>
                                        <input type="file" id="file-input2" name="purchasedBill" accept="image/png, image/jpeg, image/jpg" capture="user" (change)="fileChange(3,$event)" style="display: none;">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4"></div>
                    </div>
                </ng-wizard-step>
            </ng-wizard>
        </div>
    </div>
</div>