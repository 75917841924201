import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from "src/app/api-service/api-service.service";
import { ToastrModule } from "ngx-toastr";
import { NgOtpInputModule } from  'ng-otp-input';
import { AuthGuard } from './guards/auth-guard.service';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import {NgxPaginationModule} from 'ngx-pagination';
import * as $ from "jquery";
import { Globals } from './global';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 
import {TempStorage} from './temp_storage';

//import { /*ReCaptchaV3Service,RecaptchaFormsModule,*/RecaptchaModule,RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule,RecaptchaModule } from 'ng-recaptcha';
import { HomePageComponent } from './home_page/home.component';
import { LayoutComponent } from "./layout/layout.component";
import { HeaderComponent } from "./layout/header/header.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { WalletPointComponent } from './wallet_point/wallet_point.component';
import { HistoryComponent } from './history/history.component';
import { ProductComponent } from './product/product.component';
import { ReportComponent } from './report/report.component';
import {OffersComponent} from './offers/offers.component';
import { TabsLineComponent } from './layout/tabs-line/tabs-line/tabs-line.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyHeaderComponent } from './layout/company-header/company-header.component';
import {EarnPointsComponent} from './earn-points/earn-points.component'
import { NgQrScannerModule } from 'angular2-qrscanner';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    CompanyHeaderComponent,
    WalletPointComponent,
    HistoryComponent,
    HomePageComponent,
    ReportComponent,
    OffersComponent,
    TabsLineComponent,
    DashboardComponent,
    EarnPointsComponent
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BrowserModule,
    FormsModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    HttpModule,
    NgxStarRatingModule,
    NgWizardModule.forRoot(ngWizardConfig),
    AppRoutingModule,
    //RecaptchaModule
    RecaptchaModule,
    RecaptchaFormsModule,
    NgQrScannerModule
  ],
  providers: [AuthGuard,TempStorage,ApiService,Globals, { provide: HTTP_INTERCEPTORS, useClass: AuthGuard, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
