import { Component, OnInit } from '@angular/core';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from "src/app/api-service/api-service.service";
import { CompressImageService } from "src/app/compress-image-service";
import { take } from 'rxjs/operators';
import { Router, ActivatedRoute } from "@angular/router";
declare var $ :any; 

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
	stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.dots,
    toolbarSettings: {
      toolbarExtraButtons: [
        {
          text: 'Finish', class: 'btn btn-info', event: () => {
           this.submitReport(); 
          }
        }
      ],
    }
  };

	loader:boolean = false;
	frontImage:any = null;
	backImage:any = null;
	purchasedBill:any = null;
	code;
	id;

  constructor( private router: Router, private  compressImage: CompressImageService,private ngWizardService: NgWizardService,activatedRoute:ActivatedRoute,public toastr: ToastrService,private apiService: ApiService) {
  	activatedRoute.params.subscribe((params) => {
     
      this.id = params["id"];
      this.code = params["code"];
    });
   }

  ngOnInit(): void {
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    return false;
  }

  step1Enter: boolean = true;
  step2Enter: boolean = false;
  step3Enter: boolean = false;
  

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return (true);
  }


  fileChange(num,event){
  	if(num == 1){
  		if (event.target.files.length > 0) {
	      if (!String(event.target.files[0].type).includes("image/")) {
	        this.toastr.error("Please select images");
	        $('#file-input').val('');
	        return;
	      }

	      if (event.target.files[0].size > 2097152) {
          this.compressImages(event,num);
	        //this.toastr.error("Please select file less then 2 Mb");
	        //$('#file-input').val('');
	        //return;
          this.step2Enter = true;
	      } else {
          this.step2Enter = true;
	        this.frontImage = event.target.files[0];
	      }
	    }else {
	      this.frontImage = null;
	    }
  		
  	}else if (num == 2){
  		if (event.target.files.length > 0) {
	      if (!String(event.target.files[0].type).includes("image/")) {
	        this.toastr.error("Please select images");
	        $('#file-input1').val('');
	        return;
	      }

	      if (event.target.files[0].size > 2097152) {
          this.compressImages(event,num);
	        //this.toastr.error("Please select file less then 2 Mb");
	        //$('#file-input1').val('');
	        //return;
          this.step3Enter = true;
	      } else {
	        this.backImage = event.target.files[0];
          this.step3Enter = true;
	      }
	    }else {
	      this.backImage = null;
	    }
  		
  	}else {
  		if (event.target.files.length > 0) {
	      if (!String(event.target.files[0].type).includes("image/")) {
	        this.toastr.error("Please select images");
	        $('#file-input2').val('');
	        return;
	      }

	      if (event.target.files[0].size > 2097152) {
          this.compressImages(event,num);
	        //this.toastr.error("Please select file less then 2 Mb");
	        //$('#file-input2').val('');
	        //return;
	      } else {
	        this.purchasedBill = event.target.files[0];
	      }
	    }else {
	      this.purchasedBill = null;
	    }  		
  	}
  }

    compressImages(events,num){
      console.log(events.target.files);
      let image: File = events.target.files[0]
      console.log(`Image size before compressed: ${image.size} bytes.`)

      this.compressImage.compress(image)
        .pipe(take(1))
        .subscribe(compressedImage => {
          if(num == 1){
            this.frontImage = compressedImage
          }else if (num == 2){
            this.backImage = compressedImage
          }else {
            this.purchasedBill = compressedImage
          }
          console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
          // now you can do upload the compressed image 
        })
    }


  	submitReport(){
  		if(this.frontImage == null){
  			return this.toastr.error('Front Image is Required');
  		}else if (this.backImage == null){
  			return this.toastr.error('back Image is Required');
  		}else if (this.purchasedBill == null){
  			return this.toastr.error('Purchased Bill is Required');
  		}
  		let customer_id = localStorage.getItem('customer_id');
  		let formdata = new FormData();
        formdata.append("frontImage",  this.frontImage);
        formdata.append("backImage", this.backImage);
        formdata.append("purchasedBill", this.purchasedBill);
        formdata.append("product_id", this.id);
        formdata.append("code", this.code);
        formdata.append("customer_id", customer_id);
  		this.apiService.post('user/addReport',formdata).subscribe((response: any) => {
	        if(response.status == 200 && response.body.success == 1){
	          this.toastr.success(response.body.message);
            this.router.navigate(['/user/product/' + this.code])
	        }else {
            this.toastr.error(response.body.message);
          }
	      },
	    (error) => {
	        this.loader = false;
	        console.log(error);
	    })
  	}

}

