<div style="width:100%;height: 50px;background: white;font-size: 12px;padding-right:5px;padding-left: 5px;">
    <div class="row text-center nav_links justify-content-center" style="padding-left: 4px;
    padding-right: 4px;">
        <div class="col text-center greycolor" style="margin-top: 7px;" routerLink='/dashboard'
            [routerLinkActive]="['active']">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px">
                    <defs>
                        <style>
                            .a {
                                fill: rgba(255, 255, 255, 0);
                            }

                            .b {
                                fill: #bababa;
                                stroke: #bababa;
                                stroke-width: 0.2px;
                            }
                        </style>
                    </defs>
                    <g transform="translate(-254.9 -761)"></g>
                    <path class="b"
                        d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 11 21 L 11 15 L 13 15 L 13 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z M 12 4.7910156 L 18 10.191406 L 18 11 L 18 19 L 15 19 L 15 13 L 9 13 L 9 19 L 6 19 L 6 10.191406 L 12 4.7910156 z" />
                </svg>
                <div>Home </div>
        </div>

        <div class="col text-center greycolor" style="margin-top: 7px;margin-right: 5px;"
            routerLink='/user/wallet_point' [routerLinkActive]="['active']">
            <svg xmlns="http://www.w3.org/2000/svg" width="20.2" height="20" viewBox="0 0 20.2 20">
                <defs>
                    <style>
                        .a {
                            fill: rgba(255, 255, 255, 0);
                        }

                        .b {
                            fill: #bababa;
                            stroke: #bababa;
                            stroke-width: 0.2px;
                        }
                    </style>
                </defs>
                <g transform="translate(-254.9 -761)">
                    <rect class="a" width="20" height="20" transform="translate(255 761)" />
                    <path class="b"
                        d="M10.951,966.362A1.958,1.958,0,0,0,9,968.313v13.9a1.714,1.714,0,0,0,1.707,1.707H27.293A1.714,1.714,0,0,0,29,982.216V971a1.714,1.714,0,0,0-1.707-1.707h-1.22V966.85a.507.507,0,0,0-.488-.488Zm0,.976H25.1v1.951H10.951a.976.976,0,0,1,0-1.951ZM9.976,970a1.935,1.935,0,0,0,.976.267c5.494,0,11.62,0,16.341,0a.72.72,0,0,1,.732.732v2.927H20.951a1.228,1.228,0,0,0-1.22,1.219v2.927a1.228,1.228,0,0,0,1.22,1.22h7.073v2.927a.72.72,0,0,1-.732.732H10.707a.72.72,0,0,1-.732-.732Zm10.976,4.9h7.073v3.415H20.951a.233.233,0,0,1-.244-.244v-2.927A.233.233,0,0,1,20.951,974.9Zm2.439.732a.976.976,0,1,0,.976.976A.976.976,0,0,0,23.39,975.63Z"
                        transform="translate(246 -204.362)" />
                </g>
            </svg>
            <div>Wallet</div>
        </div>

        <div class="col text-center" style="color: black;" routerLink='/user/earn_points'
            [routerLinkActive]="['active']">
            <div class="circle justify-content-center">
                <svg class="circleimg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1" baseProfile="full" viewBox="-4 -4 29 29" width="116" height="116"
                    id="qrcode-a3ec30fc5004bbeb3c6b140362b3aee1">
                    <symbol id="tracker">
                        <path d="m 0 7 0 7 7 0 0 -7 -7 0 z m 1 1 5 0 0 5 -5 0 0 -5 z m 1 1 0 3 3 0 0 -3 -3 0 z"
                            style="fill:#000000;stroke:none"></path>
                    </symbol>
                    <use x="0" y="-7" xlink:href="#tracker"></use>
                    <use x="0" y="7" xlink:href="#tracker"></use>
                    <use x="14" y="-7" xlink:href="#tracker"></use>
                    <path
                        d="M10,6 h1v4h-1v-1h-1v-1h-1v-2h1v1h1v-1 M14,8 h1v1h1v3h1v1h-2v-1h-1v-1h1v-1h-1v1h-1v-1h-1v-4h-1v-1h-1v-1h-1v-3h-1v-1h5v1h-1v1h-1v1h1v1h1v5h1v-1 M10,1 v1h1v-1h-1 M11,10 h1v1h1v2h2v1h3v1h-3v1h-1v-1h-1v1h-2v-1h1v-1h-2v4h-2v-2h1v-2h-1v-1h-2v-1h2v-2h2v1h1v-1 M10,12 v1h2v-1h-2 M9,11 v1h1v-1h-1 M18,10 h3v4h-3v-2h-1v-1h1v-1 M19,12 v1h1v-1h-1 M4,11 h2v1h-1v1h-3v-1h-1v1h-1v-2h1v-2h-1v-1h2v3h1v1h1v-1 M12,17 h1v1h1v2h-1v1h-1v-2h-1v2h-3v-2h2v-1h2v-1 M19,18 h2v1h-1v2h-1v-1h-1v1h-3v-3h1v-1h1v-1h1v2h-1v1h2v-1 M6,8 v1h1v-1h-1 M20,8 v1h1v-1h-1 M3,10 v1h1v-1h-1 M6,10 v1h1v-1h-1 M18,15 v1h1v-1h-1 M13,16 v1h1v-1h-1 M15,16 v1h1v-1h-1 M3,8 h2v2h-1v-1h-1z M19,15 m1,0h1v2h-2v-1h1z"
                        style="fill:#000000;stroke:none"></path>
                </svg>
            </div>
        </div>

        <div class="col text-center offertab greycolor" style="margin-top: 7px;margin-left: 5px;" routerLink='/user/offers'
            [routerLinkActive]="['active']">
            <svg class="filter-green" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g transform="translate(-225 -811)">
                    <rect class="a" width="20" height="20" transform="translate(225 811)" />
                    <g transform="translate(207 793)">
                        <path class="b"
                            d="M36.594,25.688l.125-2.344a.67.67,0,0,0-.344-.594l-2.094-1.062-1.062-2.094a.615.615,0,0,0-.594-.344l-2.344.125-1.969-1.281a.677.677,0,0,0-.687,0l-1.969,1.281-2.312-.125a.615.615,0,0,0-.594.344l-1.062,2.094L19.594,22.75a.67.67,0,0,0-.344.594l.125,2.344-1.281,1.969a.677.677,0,0,0,0,.688l1.281,1.969-.125,2.344a.67.67,0,0,0,.344.594l2.094,1.063,1.063,2.094a.615.615,0,0,0,.594.344l2.344-.125,1.969,1.281a.677.677,0,0,0,.688,0l1.969-1.281,2.344.125a.615.615,0,0,0,.594-.344l1.063-2.094,2.094-1.062a.67.67,0,0,0,.344-.594l-.125-2.344,1.281-1.969a.677.677,0,0,0,0-.687Zm-1.156,4.094a.564.564,0,0,0-.094.375l.125,2.156-1.906.969a.613.613,0,0,0-.281.281l-.969,1.906-2.125-.125a.742.742,0,0,0-.375.094L28,36.625l-1.781-1.187a.63.63,0,0,0-.344-.094h-.031l-2.156.125-.969-1.906a.613.613,0,0,0-.281-.281l-1.906-.969.125-2.156a.742.742,0,0,0-.094-.375L19.375,28l1.188-1.781a.564.564,0,0,0,.094-.375l-.125-2.156,1.906-.969a.613.613,0,0,0,.281-.281l.969-1.906,2.156.125a.742.742,0,0,0,.375-.094L28,19.375l1.781,1.188a.564.564,0,0,0,.375.094l2.156-.125.969,1.906a.613.613,0,0,0,.281.281l1.906.969-.125,2.125a.742.742,0,0,0,.094.375l1.188,1.781Z" />
                        <path class="b"
                            d="M37.112,38.525A2.313,2.313,0,1,0,34.8,36.212,2.307,2.307,0,0,0,37.112,38.525Zm0-3.406a1.063,1.063,0,1,1-1.062,1.063A1.054,1.054,0,0,1,37.112,35.119Z"
                            transform="translate(-11.55 -10.931)" />
                        <path class="b"
                            d="M52.712,51.3a2.313,2.313,0,1,0,2.313,2.313A2.307,2.307,0,0,0,52.712,51.3Zm0,3.406a1.063,1.063,0,1,1,1.063-1.062A1.054,1.054,0,0,1,52.712,54.706Z"
                            transform="translate(-22.275 -22.894)" />
                        <rect class="b" width="11.344" height="1.25" transform="translate(23.558 31.558) rotate(-45)" />
                    </g>
                </g>
            </svg>
            <div>Offers</div>
        </div>
        <div class="col text-center greycolor" style="margin-top: 7px;" routerLink='/user/history'
            [routerLinkActive]="['active']">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.4" height="19.4" viewBox="0 0 19.4 19.4">
                <defs>
                    <style>
                        .c {
                            fill: none;
                            stroke: #bababa;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 1.4px;
                        }
                    </style>
                </defs>
                <g transform="translate(-1.3 -1.3)">
                    <circle class="c" cx="9" cy="9" r="9" transform="translate(2 2)" />
                    <path class="c" d="M12,6v6l4,2" transform="translate(-1 1)" />
                </g>
            </svg>
            <div>History</div>
        </div>
    </div>
</div>
