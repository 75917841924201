<div class="loader_wrapper" *ngIf="loader">
    <div class="batch_loader"></div>
</div>

<div class="row justify-content-center text-center main row-margin-0">
    <div style="width: 100%;max-width: 400px;padding: 0px;">

        <div class="tabbable-panel m-0 p-0">
            <div class="tabbable-line m-0 p-0">
                <ul class="nav nav-tabs m-0 p-0">
                    <div style="display: inline-flex;width:100%">
                        <div class="col-6 nav-item m-0 p-0 navtext" [ngClass]="{'active':type== 1}">
                            <!-- <li class="nav-item"  [ngClass]="{'active':type== 1}"> -->
                            <a class="nav-link" [ngClass]="{'active':type== 1}" href="javascript:void(0)"
                                (click)="type=1"><span>Offers</span></a>
                            <!-- </li> -->
                        </div>
                        <div class="col-6 m-0 p-0">
                            <li class="nav-item" [ngClass]="{'active':type== 2}">
                                <a class="nav-link" [ngClass]="{'active':type== 2}" href="javascript:void(0)"
                                    (click)="type=2"><span>Loyalty Rewards</span></a>
                            </li>
                        </div>
                    </div>
                </ul>
            </div>
        </div>

        <div class="skybg p-4">
            <!-- <div class="mt-0 aboutus" [hidden]="type != 1">
                <div class="row justify-content-center text-center" style="font-size: 14px;">
                    <div class="col-12 p-0 m-0 offer-card">
                        <img class="offer-tag" src="../../assets//img/offer-tag.png">
                        <img class="offer-card-img" style="width:100%;height: 130px;"
                            src="../../assets/img/offer-card.png">
                        <span class="offer-product">Attila Gold</span>
                        <span class="offer-point">500</span>
                        <span class="offer-point-text">Points</span>
    
                    </div>
                </div>
                <div class="row justify-content-center text-center" style="font-size: 14px;">
                    <div class="col-12 p-0 m-0 offer-card">
                        <img class="offer-tag" src="../../assets//img/offer-tag.png">
                        <img class="offer-card-img" style="width:100%;height: 130px;"
                            src="../../assets/img/offer-card.png">
                        <span class="offer-product">Attila Gold</span>
                        <span class="offer-point">500</span>
                        <span class="offer-point-text">Points</span>
    
                    </div>
                </div>
                

            </div> -->

            <div class="text-center mt-3" [hidden]="type != 1">
                <div class="row justify-content-center text-center row-margin-0" style="font-size: 14px;">
                    <div class="col-12 p-0 m-0 offer-card" *ngFor="let offer of offers">
                        <!-- <div>
                        <img class="offer-tag" src="../../assets//img/offer-tag.png">
                        <img class="offer-card-img" style="width:100%;height: 130px;"
                            src="../../assets/img/offer-card.png">
                        <span class="offer-product">{{offer.draw_name}}</span>
                        <span class="offer-draw-desc">{{offer.draw_desc}}</span>
                        </div> -->
                        <img class="lucky-draw-img" src="{{helper.image_url}}luckydraws/{{offer.image}}" (click)="offerDetail(offer)">

                    </div>

                    <div *ngIf="offers.length == 0" class="mt-5">
                        <img src="../../assets/img/M.A..png" style="width: 90px !important; height: auto !important;" alt="">
                        <h5 class="mt-4" style="color: #b3b3b3;" >Sorry! No offers available at the moment</h5>
                    </div>
                </div>

            </div>
            <div class="text-center mt-3" [hidden]="type == 1">
                <div class="row justify-content-center text-center row-margin-0" style="font-size: 14px;">
                    <div class="col-12 p-0 m-0 offer-card mb-2" *ngFor="let data of loyaltyPoints">
                        <div class="row col-12 pl-0 pr-0 ml-0 mr-0 mb-4">
                            <div class="col-12 p-0" style="position: absolute;">
                                <img class="offer-card-img" style="width:100%;height: 130px;"
                                    src="../../assets/img/offer-card-2.png">
                            </div>
                            <div class="col-3" style="padding-top: 7%;">
                                <img class="offer-tag-lp" src="../../assets/img/offer-tag.png">
                            </div>
                            <div class="col-6 text-left pr-0" style="padding-top: 13%;">
                                <div class="offer-product-lp">{{data.product}}</div>
                                <div
                                    class="offer-draw-desc-lp">{{data.lvl==1?"Product":(data.lvl==4?"Shipper":"Invalid")}}</div>
                            </div>
                            <div class="col-auto p-0">
                                <div class="offer-point-upto-lp">upto</div>
                                <div class="offer-point-lp">{{data.max_points}}</div>
                                <div class="offer-point-text-lp">Points</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="loyaltyPoints.length == 0" class="mt-5">
                        <img src="../../assets/img/M.A.df.png" style="width: 90px !important; height: auto !important;" alt="">
                        <h5 class="mt-4" style="color: #b3b3b3;" >Sorry!<br>No Loyalty Rewards are available at the moment!</h5>
                    </div>
                </div>
                <!-- <div class="row justify-content-center text-center" style="font-size: 14px;">
                    <div class="col-12 p-0 m-0 offer-card">
                        <img class="offer-tag" src="../../assets//img/offer-tag.png">
                        <img class="offer-card-img" style="width:100%;height: 130px;"
                            src="../../assets/img/offer-card-2.png">
                        <span class="offer-product">Attila Gold</span>
                        <span class="offer-point-upto">upto</span>
                        <span class="offer-point">500</span>
                        <span class="offer-point-text">Points</span>
    
                    </div>
                </div> -->
            </div>

        </div>
        <button id="open_offer" data-toggle="modal" data-target="#offer_details" style="display: none;">open</button>

        <div style="height: 150px;"></div>

    </div>
</div>


<!-- Offer Modal -->
<div class="modal fade" id="offer_details" tabindex="-1" role="dialog" aria-hidden="true">

    <div class="modal-dialog  modal-sm modal-dialog-centered " role="document">

        <div class="modal-content box-shadow">
            <div class="modal-body pr-4 pl-4" *ngIf="selectedOffer!=null">

                <div class="row">
                    <div class="col-12">
                        <svg data-dismiss="modal" style="float:right" xmlns="http://www.w3.org/2000/svg" width="22"
                            height="22" viewBox="0 0 22 22">
                            <defs>
                                <style>
                                    .cl {
                                        fill: none;
                                        stroke: #909090;
                                        stroke-linecap: round;
                                        stroke-linejoin: round;
                                        stroke-width: 2px;
                                    }
                                </style>
                            </defs>
                            <g transform="translate(-1 -1)">
                                <circle class="cl" cx="10" cy="10" r="10" transform="translate(2 2)" />
                                <line class="cl" x1="6" y2="6" transform="translate(9 9)" />
                                <line class="cl" x2="6" y2="6" transform="translate(9 9)" />
                            </g>
                        </svg>
                    </div>
                </div>

                <div class="row text-center justify-content-center">
                    <strong>{{selectedOffer.draw_name}}</strong>

                </div>
                <div class="row text-center justify-content-center">
                    <img src="{{selectedOffer!=null ? selectedOffer.image : ''}}" class="lucky-draw-img pt-2">
                </div>
                <hr>
                <div>
                    <div><strong>Description</strong></div>

                    <div class="text-align-justify">{{selectedOffer.draw_desc}}</div>
                </div>

                <hr>
                <div>
                    <div><strong>Terms & Conditions</strong></div>
                    <div class="pre-wrap">{{selectedOffer.t_and_c}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ----------------- -->

<!-- <button id="open_offer" class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal"
    data-target="#offer_details" style="display: none;">open</button> -->

<!-- <button id="open_offer"  data-toggle="modal"
    data-target="#offer_details" style="display: none;">open</button> -->