import { Component, OnInit } from '@angular/core';
import { HelperService } from "src/app/helper.service";
import { ApiService } from "src/app/api-service/api-service.service";
import { TempStorage } from '../temp_storage';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loader = true;
  name;
  total;

  constructor(
    private helper: HelperService,
    private apiService: ApiService,
    public temp_storage: TempStorage) {
    console.log("temp", temp_storage);
    temp_storage.isWhiteBackground = false;

  }

  ngOnInit(): void {
    this.getRewardTotal();
    this.name = localStorage.getItem('name');
  }

  getRewardTotal() {
    this.apiService.get('user/getRewardPointTotal?customer_id=' + localStorage.getItem("customer_id"), false).subscribe((response: any) => {
      this.loader = false;
      console.log("total resp", response);

      if (response.status == 200 && response.body.success == 1) {
        this.total = response.body.total;
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

}
