<header class="main-header">
  <div class="container-fluid" style="position: relative;">
    <div class="row align-items-center custom_position">
      <div class="col-auto pl-0">
        <button class="btn pink-gradient btn-icon" id="left-menu">
          <i class="material-icons">widgets</i>
        </button>
        <a href="#" class="logo"
          ><img src="assets/images/141.png" alt="" style="border-radius: 25px;" /><!-- <span
            class="text-hide-xs"
            ><b>TrustTags</b></span
          > --></a
        > 
        <!--    <form class="search-header">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search by TT Code">
                        <div class="input-group-append">
                            <button class="btn " type="button"><i class="material-icons">search</i></button>
                        </div>
                    </div>

                </form> -->
      </div>
      <!--<div class="col text-center p-xs-0" *ngIf="adminId">
        <ul class="time-day">
          <li class="text-right">
            You are logged into {{ companyName }} company
            <a href="javascript:void(0);" (click)="switchToAdminAccount()"
              >Switch Back to Admin account</a
            >
          </li>
        </ul>
      </div>-->
      <!--   <div class="col text-center p-xs-0">
                <ul class="time-day">
                    <li class="text-right">
                        <p class="header-color-primary"><span class="header-color-secondary">November</span><small>2018</small></p>
                        <h2>7</h2>
                    </li>
                    <li class="text-left">
                        <h2>36<span class="header-color-secondary font-weight-light"><sup>o</sup>C</span></h2>
                        <p class="header-color-primary text-hide-lg"><span class="header-color-secondary">Ahmedabad</span><small>11:24 am</small></p>
                    </li>
                </ul>

            </div> -->
      <div class="col-auto pr-0 custom_right">
        <!--   <div class="dropdown d-inline-block">
                    <a class="btn header-color-secondary btn-icon dropdown-toggle caret-none" href="#" role="button" id="dropdownmessage2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <figure class="avatar avatar-24 vm d-inline-block border"><img src="assets/img/india.png" alt=""></figure>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownmessage2">
                        <div class="dropdown-item">
                            <figure class="avatar avatar-24 mr-2 vm d-inline-block"><img src="assets/img/india.png" alt=""></figure> India
                        </div>
                        <div class="dropdown-item">
                            <figure class="avatar avatar-24 mr-2 vm d-inline-block"><img src="assets/img/canada.png" alt=""></figure> Canada
                        </div>
                        <div class="dropdown-item">
                            <figure class="avatar avatar-24 mr-2 vm d-inline-block"><img src="assets/img/unitedstates.png" alt=""></figure> United States
                        </div>
                        <div class="dropdown-item">
                            <figure class="avatar avatar-24 mr-2 vm d-inline-block"><img src="assets/img/uk.png" alt=""></figure> United Kingdom
                        </div>
                        <div class="dropdown-item">
                            <figure class="avatar avatar-24 mr-2 vm d-inline-block"><img src="assets/img/china.png" alt=""></figure> China
                        </div>
                    </div>
                </div> -->
        <!-- <div class="dropdown text-hide-lg d-inline-block">
                    <a class="btn header-color-secondary btn-icon dropdown-toggle caret-none" href="#" role="button" id="dropdownnotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">notifications_none</i>
                        <span class="status-dot pink-gradient"></span>
                    </a>
                    <div class="dropdown-menu notification-dropdown" aria-labelledby="dropdownnotification">
                        <a href="#" class="media  pink-gradient-active new">
                            <figure class="avatar avatar-40">
                                <img src="assets/img/user1.png" alt="Generic placeholder image">
                            </figure>
                            <div class="media-body">
                                <h5 class="mt-0">Media heading</h5>
                                <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.</p>
                            </div>
                        </a>
                        <a href="#" class="media  pink-gradient-active">
                            <figure class="avatar avatar-40">
                                <img src="assets/img/user1.png" alt="Generic placeholder image">
                            </figure>
                            <div class="media-body">
                                <h5 class="mt-0">Media heading</h5>
                                <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.</p>
                            </div>
                        </a>
                        <a href="#" class="media  pink-gradient-active">
                            <figure class="avatar avatar-40">
                                <img src="assets/img/user1.png" alt="Generic placeholder image">
                            </figure>
                            <div class="media-body">
                                <h5 class="mt-0">Media heading</h5>
                                <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.</p>
                            </div>
                        </a>
                    </div>
                </div> -->
        <!-- <div class="col-auto pl-0 d-inline-block">
          <button
          *ngIf="SyncAccess"
            class="btn btn-rounded pink-gradient text-uppercase pr-3"
            style="border-radius: 25px;"
            data-toggle="modal"
            data-target="#createOrder"
          >
          <i class="fas fa-sync-alt icon" style="line-height: inherit !important; vertical-align: none !important;"></i>
          Sync
          </button>
              </div> -->
        <!--<div class="dropdown d-inline-block">
          <a
            class="btn header-color-secondary dropdown-toggle username"
            href="javascript:void(0);"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <figure class="userpic">
              <img src="../../../assets/images/GSP_logo.png" alt="" />
            </figure>
            <h5 class="text-hide-xs">
              <small class="header-color-secondary">Welcome,</small>
              <span class="header-color-primary">GSP</span>
            </h5>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right profile-dropdown"
            aria-labelledby="dropdownMenuLink"
            ngbdropdownmenu
          >
            <div class="card">
              <div class="card-body text-center">
                <a href="#">
                  <figure class="avatar avatar-120 mx-auto my-3">
                    <img
                      src="../../../assets/images/GSP_logo.png"
                      alt=""
                    />
                  </figure>
                  <h5 class="card-title mb-2 header-color-primary">
                    GSP
                  </h5>
                  <h6 class="card-subtitle mb-3 header-color-secondary">South Africa</h6>
                </a>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> 
                 <button class="btn btn-sm pink-gradient border-0 mb-3">Edit</button>
              </div>
            </div>
             <a class="dropdown-item pink-gradient-active" href="javascript:void(0);" id="open-right-sidebar">
                            <div class="row align-items-center">
                                <div class="col">
                                    Setting
                                </div>

                                <div class="col-auto">
                                    <div class="header-color-secondary ml-2"><i class="material-icons vm">settings</i></div>
                                </div>
                            </div>
                        </a> 
            <div class="dropdown-divider m-0"></div>
            <div class="dropdown-divider m-0"></div>
            <a class="dropdown-item pink-gradient-active" (click)="Logout()">
              <div class="row align-items-center">
                <div class="col">
                  Logout
                </div>

                <div class="col-auto">
                  <div class="text-danger ml-2">
                    <i class="material-icons vm">exit_to_app</i>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</header>
