<div class="loader_wrapper" *ngIf="loader">
    <div class="batch_loader"></div>
</div>
<!-- content page title -->
<!--<div class="container-fluid bg-light-opac">
            <div class="row">
                <div class="container my-3 main-container">
                    <div class="row align-items-center">
                        <div class="col">
                            <h2 class="content-color-primary page-title">About Us</h2>
                            <p class="content-color-secondary page-sub-title">Creative, amazing, awesome and unique</p>
                        </div>
                        <div class="col-auto">
                            <a href="contactus1.html" class="btn btn-rounded pink-gradient text-uppercase pr-3"><i class="material-icons">mail</i> <span class="text-hide-xs">Contact us</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
<!-- content page title ends -->

<!-- content page -->
<div class="container main-container text-center text-light">
    <h1 class="mb-0">We are <span class="font-weight-light">Trust</span>Tags</h1>
    <p>World's most comprehensive authentication system</p>
    <h5 class="text-warning mb-4" *ngIf="is_already_registered != false && !isOTPOpen"><i class="material-icons mr-1">star</i><i
            class="material-icons mr-1">star</i><i class="material-icons mr-1">star</i><i
            class="material-icons mr-1">star</i><i class="material-icons mr-1">star</i></h5>
    <div [hidden]="!isGPSEnabled">
        <div>

            <div *ngIf="isOTPOpen">
                <div class="modal-header border-0  px-0">
                    <div class="col-12 text-center">
                        <h5 class="card-title mb-0">Please verify your number</h5>
                    </div>
                </div>
                <div class="modal-body text-center pr-4 pl-4">
                    <label for="" class="mb-2">Enter OTP</label>
                    <div class="row mb-2">
                        <ng-otp-input style="width:100%" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config">
                        </ng-otp-input>
                    </div>
                    <div class="mb-2" *ngIf="otp_time != 0">
                        {{otp_time}} sec remaining
                    </div>
                    <div class="text-center" *ngIf="otp_time == 0">
                        <button (click)="resend_otp_verification()" class="mb-2 btn btn-light text-dark btn-rounded">Re-Send
                            OTP</button>
                    </div>
                    <br>
                </div>
            </div>

            <form [formGroup]="loginForm" [hidden]="isOTPOpen">
                <div class="modal-header border-0  px-0" *ngIf="is_already_registered != false">
                    <div class="col">
                        <h5 class="card-title mb-0">
                            {{(is_already_registered == null) ? "Hi There! Do I know you?": "Hi Buddy, What do I call you?"}}
                        </h5>
                    </div>
                </div>
                <div class="modal-body">                    
                    <div class="input-group number-input-main mb-2 mt-4" *ngIf="is_already_registered == null">

                        <input class="number-input" placeholder="Phone Number" type="number" formControlName="phone"
                            (keypress)='number_validation($event)' [ngClass]="{
                            'is-invalid': submitted && f.phone.errors
                        }" />
                        <span class="country-code">+91</span>

                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">
                                Phone is required
                            </div>
                            <div *ngIf="f.phone.errors.pattern">
                                Phone number is invalid
                            </div>
                        </div>
                    </div>

                    <div class="center-group mb-1 mt-2" *ngIf="is_already_registered == false">
                        <label for="" class="pull-left">Full Name</label>
                    </div>

                    <div class="center-group mb-3 mt-4" *ngIf="is_already_registered == false">
                        <input type="text" class="form-control border-light" placeholder="Enter your full name"
                            formControlName="first_name" [ngClass]="{
                                                'is-invalid': submitted && f.first_name.errors
                                            }">

                        <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                            <div *ngIf="f.first_name.errors.required">
                                Full Name is required
                            </div>
                        </div>
                    </div>

                    <div class="center-group mb-1 mt-2" *ngIf="is_already_registered == false">
                        <label for="" class="pull-left">Gender</label>
                    </div>
                    <div class="center-group mb-3 mt-4" *ngIf="is_already_registered == false">
                        <select class="custom-select btn-block" formControlName="gender" [ngClass]="{
                                            'is-invalid': submitted && f.gender.errors
                                        }">
                            <option value="">-- Select --</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                            <div *ngIf="f.gender.errors.required">
                                Gender is required
                            </div>
                        </div>
                    </div>
                    <div class="center-group mb-1 mt-2" *ngIf="is_already_registered == false">
                        <label for="" class="pull-left">Date of Birth
                            (MM-YYYY)</label>
                    </div>
                    <div class="center-group mb-3 mt-4" *ngIf="is_already_registered == false">
                        <select class="custom-select btn-block" style="width: 45%;" formControlName="dobMonth"
                            [ngClass]="{
                                            'is-invalid': submitted && f.dobMonth.errors
                                        }">
                            <option [ngValue]="month.val" *ngFor="let month of months">{{month.name}}</option>
                        </select> &nbsp; - &nbsp;
                        <select class="custom-select btn-block mt-0" style="width: 44%;" formControlName="dobYear"
                            [ngClass]="{
                            'is-invalid': submitted && f.dobYear.errors
                        }">
                            <option [ngValue]="year" *ngFor="let year of years">{{year}}</option>
                        </select>
                    </div>

                    <div class="input-group number-input-main mb-2 mt-4">
                        <div id="recaptcha-container"></div>
                    </div>
                    <div class="text-center mt-5">
                        <div class="arrow-button-main mt-5 text-center" *ngIf="is_already_registered != false">
                            <div class="arrow-button" (click)="login()">
                                <i class="fas fa-chevron-right" style="font-size: xx-large; padding-left: 14%;"></i>
                            </div>
                        </div>
                        <!-- <button *ngIf="is_already_registered != false"
                            class="btn btn-circle btn-outline-light btn-block col" (click)="login()">Login</button> -->
                        <button type="submit" *ngIf="is_already_registered == false"
                            class="btn btn-circle btn-outline-light btn-block col"
                            (click)="register_customer(true)">Sign Up</button>
                        <br>
                    </div>
                    <button id="otp_open" class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal"
                        data-target="#otp_verification" style="display: none;">open</button>
                </div>
            </form>
        </div>
    </div>
    <p class="content-color-secondary pink-gradient rounded" *ngIf="!isGPSEnabled">Allow GPS Permissions and refresh the
        page</p>
</div>

