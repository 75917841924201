<div class="loader_wrapper" *ngIf="loader">
    <div class="batch_loader"></div>
</div>

<!-- <div class="container-fluid bg-light-opac">
    <div class="row">
        <div class="container-fluid my-3 main-container">
            <div class="row align-items-center">
                <div class="col-1">
                </div>
                <div class="col-12 mt-1" style="display:inline-flex;">
                    <div class="media" >
                        <div class="icon-rounded icon-50 pink-gradient mr-3">
                            <i class="material-icons">account_balance_wallet</i>
                        </div>
                        <div class="media-body">
                            <p class="content-color-secondary mb-0">Total Earned Points</p>
                            <h4 class="content-color-primary mb-3" *ngIf="helper.reward_total != null">
                                {{helper.reward_total}}</h4>
                            <h4 class="content-color-primary mb-3" *ngIf="helper.reward_total == null">0</h4>
                        </div>
                     
                    </div>
                    <div style="position:absolute;right: 30px;" [routerLink]="['/user/offers']" routerLinkActive="router-link-active" >
                        <img src="assets/img/birtmsg.png" height="40px" width="40px">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="main pr-3 pl-3">
    <div class="row wallet p-3">
        <div class="card p-3">
            <!-- <div class="total-earned-text">
                <div style="margin-top: 12px;margin-right: 45%;">Total Earned</div>
            </div> -->
            <div class="card-circle ml-3">
                <div class=" text-center">
                    <div class="inner-circle-text">
                        <span
                            style="font-weight: 1000;font-size: 35px;">{{helper.reward_total?helper.reward_total:"0"}}</span>
                        <br>
                        <span style="font-size:13px;">APP Wallet Points</span>
                    </div>
                </div>
            </div>
            <div class="card-circle-paytm mr-3">
                <div class=" text-center">
                    <div class="inner-circle-text">
                        <span style="font-weight: 1000;font-size: 35px;">₹ {{helper.payTmAmountTotal}}</span>
                        <br>
                        <span style="font-size:13px;">PayTM Wallet</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 bottom-15">

            <div class="tabbable-panel m-0 p-0">
                <div class="tabbable-line m-0 p-0">
                    <ul class="nav nav-tabs m-0 p-0 text-center">
                        <div style="display: inline-flex;width:100%">
                            <div class="col-4 nav-item m-0 p-0 navtext" [ngClass]="{'active':type== 1}">
                                <!-- <li class="nav-item"  [ngClass]="{'active':type== 1}"> -->
                                <a class="nav-link" [ngClass]="{'active':type== 1}" href="javascript:void(0)"
                                    (click)="type=1"><span>Earnings</span></a>
                                <!-- </li> -->
                            </div>
                            <div class="col-4 m-0 p-0">
                                <li class="nav-item" [ngClass]="{'active':type== 2}">
                                    <a class="nav-link" [ngClass]="{'active':type== 2}" href="javascript:void(0)"
                                        (click)="type=2"><span>Rewards</span></a>
                                </li>
                            </div>

                            <div class="col-4 m-0 p-0">
                                <li class="nav-item" [ngClass]="{'active':type== 3}">
                                    <a class="nav-link" [ngClass]="{'active':type== 3}" href="javascript:void(0)"
                                        (click)="type=3"><span>Redeemed</span></a>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>

            <div class="skybg p-4">
                <div class="text-center mt-2" [hidden]="type == 2 || type==3">
                    <div class="reward-panel">
                        <div *ngFor="let card of earning_list">
                            <div class="inner-cards" *ngIf="card.card_type==1 && card.is_scanned==true">
                                <div class="grey-border">
                                    <img class="earned-bg" src="../../assets/img/fw-2x.png">
                                    <span class="earned-points">{{card.points}} Points</span><br>
                                    <span class="earned-points-earned">
                                        <small>{{card.product}}</small>
                                    </span>
                                    <div class="earned-points-earned">
                                        <small>PayTM Status: <span [ngClass]="{'text-danger': card.paymentStatus == 'FAILED', 'text-success': card.paymentStatus == 'PAID','text-primary': card.paymentStatus == 'ORDERED'}">{{card.paymentStatus}}</span></small>
                                    </div>
                                    <div class="earned-points-date mt-2">{{card.createdAt | date:'dd-MMM-yyyy'}}</div>
                                </div>
                            </div>

                            <div class="inner-cards" *ngIf="card.card_type==1 && card.is_scanned==false"
                                (click)="open_point_scratch_card(card.id,card.points,card.code)">
                                <img class="scratch-card-bg" src="../../assets/img/blue-card.jpg">
                                <!-- <img class="reward-circle" src="../../assets/img/reward-circle.png"> -->
                            </div>

                            <div class="inner-cards" *ngIf="card.card_type==2 && card.is_locked==true">
                                <img class="scratch-card-bg" src="../../assets/img/scratch-card-bg.png">
                                <img class="circle-locked" src="../../assets/img/circle-locked.png">
                                <span class="unlock-days">Unlock
                                    {{(card.unlock_in == 0) ? "By Today" : "in " + card.unlock_in + " days"  }}</span>
                            </div>
                            <div class="inner-cards"
                                *ngIf="card.card_type==2 && (card.is_locked==false && card.is_scanned==false)"
                                (click)="open_reward_scratch_card(card.id,card.reward,card.card_type)">
                                <img class="scratch-card-bg" src="../../assets/img/scratch-card-bg.png">
                                <img class="circle-unlocked" src="../../assets/img/circle-unlocked.png">
                            </div>

                            <div class="inner-cards"
                                *ngIf="card.card_type==2 && (card.is_locked==false && card.is_scanned==true)"
                                (click)="redeemLuckyRewardOpen(card.id, card.reward)">
                                <div class="grey-border">
                                    <img class="earned-bg" src="../../assets/img/rewards.png">
                                    <img class="reward-img" src="{{helper.image_url}}rewards/{{card.reward_image}}"
                                        style="top: 24%;">
                                    <span class="reward-name" style="bottom: 41%;">{{card.reward}}</span><br>
                                    <!-- <span class="required-points">{{reward.points}}</span><br>
                                    <span class="reward-stock">{{reward.stock}} in stock</span> -->
                                </div>
                            </div>
                            <!-- <div class="inner-cards">
                            <div class="grey-border">
                            <img class="earned-bg" src = "../../assets/img/fw-2x.png">
                            <span class="earned-points">You've earned 1000 points</span><br>
                            <span class="earned-points-date">29-Sep-2021</span>
                            </div>
                        </div> -->


                        </div>
                    </div>
                    <div *ngIf="earning_list.length == 0" class="text-center">
                        <img src="../../assets/img/M.A.f.png" class="mt-2" width="90px" alt="">
                        <h5 style="color: #b3b3b3;" class="mt-4">Oops! You have not Earned any Points</h5>
                    </div>

                </div>
                <div class="text-center mt-2" [hidden]="type == 1 || type ==3">
                    <div class="reward-panel">
                        <div class="inner-cards" *ngFor="let reward of reward_list">
                            <!-- [ngClass]="{'grey-border':reward.points>helper.reward_total,'green-border':reward.points<=helper.reward_total}" -->
                            <div lass="grey-border" *ngIf="reward.reward_points <= helper.reward_total"
                                (click)="redeemRewardOpen(reward.id,reward)">
                                <img class="reward-img" src="{{helper.image_url}}{{reward.main_image}}">
                                <img class="earned-bg grey-border1" src="../../assets/img/rewards.png">
                                <span class="reward-name">{{reward.reward_name}} </span><br>
                                <span class="required-points text-success"><b>{{reward.reward_points}}
                                        Points</b></span><br>
                                <span class="reward-stock">{{(reward.stock - reward.reward_stock)}} in stock</span>
                            </div>

                            <div class="grey-border" *ngIf=" reward.reward_points > helper.reward_total">
                                <img class="reward-img" src="{{helper.image_url}}rewards/{{reward.main_image}}">
                                <img class="earned-bg grey-border1" src="../../assets/img/rewards.png">
                                <span class="reward-name">{{reward.reward_name}}</span><br>
                                <span class="required-points text-success"><b>{{reward.reward_points}}
                                        Points</b></span><br>
                                <span class="reward-stock">{{(reward.stock - reward.reward_stock)}} in stock</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="reward_list.length==0" class="text-center">
                        <img src="../../assets/img/M.A.f.png" class="mt-2" width="90px" alt="">
                        <h5 style="color: #b3b3b3;" class="mt-4">Sorry! <br>No rewards available right now. All points
                            will be encashed to PayTM</h5>
                    </div>
                </div>
                <div class="text-center mt-2" [hidden]="type == 1 || type==2">
                    <div class="reward-panel">
                        <div class="inner-cards" *ngFor="let history of reward_history_list">
                            <div class="grey-border" *ngIf="history.is_verified==0">
                                <img class="reward-img" src="{{helper.image_url}}rewards/{{history.voucher_image}}">
                                <img class="earned-bg" src="../../assets/img/fw-2x.png">
                                <span class="reward-name">{{history.reward.reward_name}}</span><br>
                                <span class="redeemed-on">Redeemed on {{history.createdAt | date:'dd-MMM-yyyy'}}</span>
                                <span class="redeemed-status-inprocess">In Process</span>
                            </div>

                            <div class="grey-border" *ngIf="history.is_verified==2">
                                <img class="reward-img" src="{{helper.image_url}}rewards/{{history.voucher_image}}">
                                <img class="earned-bg" src="../../assets/img/fw-2x.png">
                                <span class="reward-name">{{history.reward.reward_name}}</span><br>
                                <span class="redeemed-on">Redeemed on {{history.createdAt | date:'dd-MMM-yyyy'}}</span>
                                <span class="redeemed-status-rejected">Rejected</span>
                            </div>

                            <div class="grey-border" *ngIf="history.is_verified==1">
                                <img class="reward-img" src="{{helper.image_url}}rewards/{{history.voucher_image}}">
                                <img class="earned-bg" src="../../assets/img/fw-2x.png">
                                <span class="reward-name">{{history.reward.reward_name}}</span><br>
                                <span class="redeemed-on">Redeemed on {{history.createdAt | date:'dd-MMM-yyyy'}}</span>
                                <span class="redeemed-status-ontheway">On its way</span>
                            </div>

                            <div class="grey-border" *ngIf="history.is_verified==3">
                                <img class="reward-img" src="{{helper.image_url}}rewards/{{history.voucher_image}}">
                                <img class="earned-bg" src="../../assets/img/fw-2x.png">
                                <span class="reward-name">{{history.reward.reward_name}}</span><br>
                                <span class="redeemed-on">Redeemed on {{history.createdAt | date:'dd-MMM-yyyy'}}</span>
                                <span class="redeemed-status-success">Delivered</span>
                            </div>


                        </div>
                    </div>
                    <div *ngIf="reward_history_list.length==0" class="text-center mt-2">
                        <img src="../../assets/img/M.A.fhf.png" class="mt-2" width="90px" alt="">
                        <h5 style="color: #b3b3b3;" class="mt-4">Oops! You've not redeemed any Rewards.</h5>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<div class="footer-blank"></div>
<!-- <div class="row" style="height: 500px;width: 500px;"> -->
<!-- <div fxLayout="column" fxFlexAlign="center">
    <div  fxFlex="20" fxFill>
    <md-input-container fxFill><textarea fxFill mdInput placeholder="qrr-value (URL or DATA URL)" [(ngModel)]="value"></textarea></md-input-container>
    <br/>

    </div>
    <div  fxFlex="60" fxFill>
    <ng2-qrcode-reader (result)="render($event)" [qrr-show]="showQRCode" [qrr-value]="value" [qrr-type]="elementType"></ng2-qrcode-reader>
    </div>
    <p>Result</p>
    <div #result  fxFlex="20" fxFill>
    </div>
    </div> -->


<!-- Modal -->
<div class="modal fade" id="card" tabindex="-1" role="dialog" aria-hidden="true">
    <button type="button" class="close" data-dismiss="modal" id="card_close" aria-label="Close" style="display: none;">
        <span aria-hidden="true">×</span>
    </button>
    <div class="modal-dialog justify-content-center modal-sm modal-dialog-centered " role="document">
        <div class="modal-content  size-220 p-0">
            <div class="modal-body text-center p-0 m-0">
                <div class="container-fluid main-container text-center row m-0 p-0">
                    <div class="" style="width: 100%">
                        <div id="js--sc--container">
                            <div *ngIf="open_point">
                                <div class="s__inner">
                                    <div style=" z-index: 9;background: white;padding: 26px;border-radius: 5px;">
                                        <div class="scratch-card-points" *ngIf="point == 0"> 
                                            <div style="font-size:2em;color:#424242;">Better Luck</div>
                                            <div style="font-size:2em;color:#424242;">Next Time</div>
                                        </div>
                                        <div class="scratch-card-points" *ngIf="point != 0">{{point}} Points</div>
                                        <img src="../../assets/img/4.png" class="fw-2x-220">
                                        <img src="../../assets/img/1.png" class="coins-01" style="margin: 0 auto;">
                                        <div class="points-won-2">You've Won</div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="open_reward==true">
                                <div class="s__inner" *ngIf="reward!=undefined">
                                    <div
                                        style=" z-index: 9;background: white;padding: 26px;border-radius: 5px; height: 220px;width: 220px;">
                                        <div style="font-size:2em;color:#424242;">{{reward}}</div>
                                        <div style="font-size:1em;color:#424242;">Won!</div>
                                    </div>
                                </div>

                                <div class="s__inner" *ngIf="reward==undefined">
                                    <div style=" z-index: 9;background: white;padding: 26px;border-radius: 5px;">
                                        <div style="font-size:2em;color:#424242;">Better Luck</div>
                                        <div style="font-size:2em;color:#424242;">Next Time</div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
                <!--<div class="text-center">
                                <button class="btn btn-circle btn-outline-light btn-block col" (click)="scratch_card()">Tap to Collect Points</button>
                            </div>-->
                <br>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="want_redeem" tabindex="-1" role="dialog" aria-hidden="true">
    <button type="button" class="close" data-dismiss="modal" id="want_redeem_close" aria-label="Close"
        style="display: none;">
        <span aria-hidden="true">×</span>
    </button>
    <div class="modal-dialog  modal-sm modal-dialog-centered " role="document">
        <div class="modal-content box-shadow">
            <div class="modal-body pr-4 pl-4">
                <form [formGroup]="redeemForm">
                    <div style="display: inline-flex;">
                        <div class="icon-circle icon-50 bg-light-primary">
                            <!-- <img src="{{helper.image_url + main_image}}" style="width: 100%;border-radius: 25px;" alt=""
                            srcset=""> -->
                            <img class="" src="../../assets/img/r-img.png">


                        </div>
                        <div class="reddemed-header-text text-justify">You are redeeming your reward for <span
                                style="color:'#C78200'">{{point}} points</span></div>
                    </div>
                    <hr>
                    <div class="mt-1 mb-3 please-fill text-justify">
                        *Please fill up below details so that we can deliver your gift to you.
                    </div>

                    <div class="m-2">
                        <div class="mt-4 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr text-left" style="width: 100%; padding-left: 1%;">Full Name</label>
                                <input type="text" class="form-control" [ngClass]="{
                                    'is-invalid': submitted && f1.first_name.errors
                                  }" formControlName="first_name" id="usr" required>
                            </div>
                            <div *ngIf="submitted && f1.first_name.errors" class="invalid-feedback margin--9">
                                <div *ngIf="f1.first_name.errors.required">
                                    Full Name is required
                                </div>
                                <div *ngIf="f1.first_name.errors.maxlength">
                                    must be less than 40 characters
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <!-- <input type="text" class="form-control" formControlName="phone" id="usr" required disabled>
                                <label for="usr" style="left: -76px;top: -61px;">Phone</label>
                                 -->
                                <label for="usr" style="width: 100%; padding-left: 1%;">Phone</label>
                                <input type="text" class="form-control" [ngClass]="{
                                    'is-invalid': submitted && f1.phone.errors
                                  }" formControlName="phone" id="usr" disabled>
                            </div>
                            <div *ngIf="submitted && f1.phone.errors" class="invalid-feedback margin--9">
                                <div *ngIf="f1.phone.errors.min || f1.phone.errors.max">
                                    Must be equal to 10 digits
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="padding-left: 1%; width: 100%;">Address</label>
                                <input type="text" class="form-control" formControlName="address" id="usr" required>
                            </div>
                            <div *ngIf="submitted && f1.address.errors" class="invalid-feedback margin--9">
                                <div *ngIf="f1.address.errors.required">
                                    Address is required
                                </div>
                                <div *ngIf="f1.address.errors.maxlength">
                                    Must be less than 40 characters
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="padding-left: 1%; width: 100%;">Pincode</label>
                                <input type="number" class="form-control" formControlName="pincode" id="usr" required>
                            </div>
                            <div *ngIf="submitted && f1.pincode.errors" class="invalid-feedback margin--9">
                                <div *ngIf="f1.pincode.errors.required">
                                    Pincode is required
                                </div>
                                <div *ngIf="f1.pincode.errors.maxlength">
                                    Must be less than 10 digits
                                </div>
                            </div>

                        </div>
                    </div>



                </form>
                <div class="text-center mt-4">
                    <a href="javascript:void(0);" data-dismiss="modal"
                        class="btn btn-rounded black-gradient btn-submit btn-size">
                        <span class="">Cancel</span></a>

                    <a href="javascript:void(0);" (click)="redeemRewards()"
                        class="btn btn-rounded pink-gradient btn-submit btn-size">
                        <span class="">Redeem</span></a>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="want_redeem" tabindex="-1" role="dialog" aria-hidden="true">
    <button type="button" class="close" data-dismiss="modal" id="want_redeem_close" aria-label="Close"
        style="display: none;">
        <span aria-hidden="true">×</span>
    </button>
    <div class="modal-dialog  modal-sm modal-dialog-centered " role="document">
        <div class="modal-content box-shadow">
            <div class="modal-body pr-4 pl-4">
                <form [formGroup]="redeemForm">
                    <div style="display: inline-flex;">
                        <div class="icon-circle icon-50 bg-light-primary">
                            <img class="" src="../../assets/img/r-img.png">
                        </div>
                        <div class="reddemed-header-text text-justify">You are redeeming your gift reward of <span
                                style="color:'#C78200'">{{point}} points</span></div>
                    </div>
                    <hr>
                    <div class="mt-1 mb-3 please-fill text-justify">*Please fill up below details so we can deliver
                        Trusttags gift to
                        you.</div>

                    <div class="m-2">
                        <div class="mt-4 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="width: 100%; padding-left: 1%;">Full Name</label>
                                <input type="text" class="form-control" [ngClass]="{
                                    'is-invalid': submitted && f1.first_name.errors
                                  }" formControlName="first_name" id="usr" required>
                            </div>
                        </div>
                        <div *ngIf="submitted && f1.first_name.errors" class="invalid-feedback margin--9">
                            <div *ngIf="f1.first_name.errors.required">
                                Full Name is required
                            </div>
                        </div>
                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="width: 100%; padding-left: 1%;"
                                    style="left: -76px;top: -61px;">Phone</label>
                                <input type="text" class="form-control" [ngClass]="{
                                    'is-invalid': submitted && f1.phone.errors
                                  }" formControlName="phone" id="usr" disabled>
                            </div>
                        </div>
                        <div *ngIf="submitted && f1.phone.errors" class="invalid-feedback margin--9">
                            <div *ngIf="f1.phone.errors.required">
                                Phone is required
                            </div>
                        </div>
                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="width: 100%; padding-left: 1%;">Address</label>
                                <input type="text" class="form-control" formControlName="address" id="usr" [ngClass]="{
                                    'is-invalid': submitted && f1.address.errors
                                  }" required>
                            </div>
                        </div>
                        <div *ngIf="submitted && f1.address.errors" class="invalid-feedback margin--9">
                            <div *ngIf="f1.address.errors.required">
                                Address is required
                            </div>
                        </div>
                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="width: 100%; padding-left: 1%;">Pincode</label>
                                <input type="text" class="form-control" formControlName="pincode" id="usr" [ngClass]="{
                                    'is-invalid': submitted && f1.pincode.errors
                                  }" required>
                            </div>
                        </div>

                        <div *ngIf="submitted && f1.pincode.errors" class="invalid-feedback margin--9">
                            <div *ngIf="f1.pincode.errors.required">
                                Pincode is required
                            </div>
                        </div>
                    </div>



                </form>
                <div class="text-center mt-4">
                    <a href="javascript:void(0);" data-dismiss="modal"
                        class="btn btn-rounded black-gradient btn-submit btn-size">
                        <span class="">Cancel</span></a>

                    <a href="javascript:void(0);" (click)="redeemRewards()"
                        class="btn btn-rounded pink-gradient btn-submit btn-size">
                        <span class="">Redeem</span></a>
                    <!-- <button class="mb-2 btn btn-light text-dark btn-rounded mr-2"
                        (click)="redeemRewards()">Redeem</button>
                    <button class="close" data-dismiss="modal"
                        class="mb-2 btn btn-light text-dark btn-rounded ml-2">Cancel</button> -->
                </div>
                <br>
                <!--<div class="text-center">
                                <button class="btn btn-circle btn-outline-light btn-block col" (click)="redirect_product()">Verify</button>
                            </div>
                            <br>-->
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="want_lucky_redeem_open" tabindex="-1" role="dialog" aria-hidden="true">
    <button type="button" class="close" data-dismiss="modal" id="want_lucky_redeem_close" aria-label="Close"
        style="display: none;">
        <span aria-hidden="true">×</span>
    </button>
    <div class="modal-dialog  modal-sm modal-dialog-centered " role="document">
        <div class="modal-content box-shadow">
            <div class="modal-body pr-4 pl-4">
                <form [formGroup]="redeemLuckyForm">
                    <div style="display: inline-flex;">
                        <div class="icon-circle icon-50 bg-light-primary">
                            <img class="" src="../../assets/img/r-img.png">
                        </div>
                        <div class="reddemed-header-text text-justify">You are redeeming your lucky draw gift reward
                        </div>
                    </div>
                    <hr>
                    <div class="mt-1 mb-3 please-fill text-justify">*Please fill up below details so we can deliver
                        Trusttags gift to
                        you.</div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="width: 100%; padding-left: 1%;">Full Name</label>
                                <input type="text" class="form-control" formControlName="first_name" id="usr" [ngClass]="{
                                    'is-invalid': luckyRedeemSubmitted && f2.first_name.errors
                                  }" required>
                            </div>
                        </div>
                        <div *ngIf="luckyRedeemSubmitted && f2.first_name.errors" class="invalid-feedback margin--9">
                            <div *ngIf="f2.first_name.errors.required">
                                Full Name is required
                            </div>
                        </div>
                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="width: 100%; padding-left: 1%;">Phone</label>
                                <input type="text" class="form-control" formControlName="phone" id="usr" [ngClass]="{
                                    'is-invalid': luckyRedeemSubmitted && f2.phone.errors
                                  }" disabled>
                            </div>
                        </div>
                        <div *ngIf="luckyRedeemSubmitted && f2.phone.errors" class="invalid-feedback margin--9">
                            <div *ngIf="f2.phone.errors.required">
                                Phone is required
                            </div>
                        </div>
                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="width: 100%; padding-left: 1%;">Address</label>
                                <input type="text" class="form-control" formControlName="address" id="usr" [ngClass]="{
                                    'is-invalid': luckyRedeemSubmitted && f2.address.errors
                                  }" required>
                            </div>
                        </div>

                        <div *ngIf="luckyRedeemSubmitted && f2.address.errors" class="invalid-feedback margin--9">
                            <div *ngIf="f2.address.errors.required">
                                Address is required
                            </div>
                        </div>
                    </div>

                    <div class="m-2">
                        <div class="mt-1 p-0">
                            <div class="form-group text-center m-0 p-0 ">
                                <label for="usr" style="width: 100%; padding-left: 1%;">Pincode</label>
                                <input type="number" class="form-control" formControlName="pincode" id="usr" [ngClass]="{
                                    'is-invalid': luckyRedeemSubmitted && f2.pincode.errors
                                  }" required>
                            </div>
                        </div>
                        <div *ngIf="luckyRedeemSubmitted && f2.address.errors" class="invalid-feedback margin--9">
                            <div *ngIf="f2.pincode.errors.required">
                                Pincode is required
                            </div>
                        </div>
                    </div>



                </form>
                <div class="text-center mt-4">
                    <a href="javascript:void(0);" data-dismiss="modal"
                        class="btn btn-rounded black-gradient btn-submit btn-size">
                        <span class="">Cancel</span></a>

                    <a href="javascript:void(0);" (click)="redeemLuckyRewards()"
                        class="btn btn-rounded pink-gradient btn-submit btn-size">
                        <span class="">Redeem</span></a>


                    <br>
                    <!--<div class="text-center">
                                <button class="btn btn-circle btn-outline-light btn-block col" (click)="redirect_product()">Verify</button>
                            </div>
                            <br>-->
                </div>
                <br>
                <!--<div class="text-center">
                                <button class="btn btn-circle btn-outline-light btn-block col" (click)="redirect_product()">Verify</button>
                            </div>
                            <br>-->
            </div>
        </div>
    </div>
</div>
<button id="want_redeeem_open" class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal"
    data-target="#want_redeem" style="display: none;">open</button>

<button id="want_lucky_redeeem" class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal"
    data-target="#want_lucky_redeem_open" style="display: none;">open</button>

<button id="card_open" class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal" data-target="#card"
    style="display: none;">open</button>