import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { ApiService } from "src/app/api-service/api-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from "jquery";
import { TempStorage } from '../temp_storage';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit, AfterViewInit {
  loader = true;
  offers = [];
  loyaltyPoints = [];
  selectedOffer: any = {
    draw_name: '',
    image: '',
    draw_desc: '',
    t_and_c: ''
  };
  type: any = 1;
  lp = false;
  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    public tempStorage: TempStorage) {
    tempStorage.isWhiteBackground = true;
    activatedRoute.queryParams.subscribe((params) => {
      this.lp = params["lp"];
      if (this.lp == true) {
        this.type = 2;
      }
    });
  }

  ngAfterViewInit() {
    document.getElementById("mainBody").style.backgroundColor = "#ff1 !important"
  }

  ngOnInit(): void {
    this.apiService.get('user/offers', true).subscribe((response: any) => {
      console.log("offer res", response.body);
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.offers = response.body.data.luckyDraw;
        this.loyaltyPoints = response.body.data.pointAllocation;

      }
    },
      (error) => {
        this.loader = false;
        console.error("err", error);
      })
  }

  offerDetail(offer) {
    this.selectedOffer = offer;
    console.log("offf", this.selectedOffer);

    $('#open_offer').click();
  }

}
