<div class="loader_wrapper" *ngIf="loader">
	<div class="batch_loader"></div>
</div>
<div class="product-view">
	<!-- content page -->
	<div class="container m-0 text-center" *ngIf="productFound == false">
		<div>
			<div class="row row-margin-0 align-items-center h-100 mt-4">
				<div class="co-md-12 col-lg-5 text-center order-lg-1">
					<img src="../../../assets/images/logo-1501.png" alt="" class="mw-100" />
				</div>
				<div class="col-md-12 col-lg-7" *ngIf="isLocation">
					<h1 class="display-4 mt-5">
						<span class="text-danger">Ohh!</span>
						<br />You've scanned invalid QR code!
					</h1>
					<br />
					<p class="lead">Please scan valid QR code</p>
					<br />
					<br />
				</div>
			</div>
		</div>
		<div class="row row-margin-0 align-items-center h-100 mt-4" *ngIf="!isLocation">
			<div class="co-md-12 col-lg-5 text-center order-lg-1">
				<img src="../../../assets/images/logo-1501.png" alt="" class="mw-100" />
			</div>
			<div class="col-md-12 col-lg-7">
				<h1 class="display-4 mt-5">
					<span class="text-danger">Opps!</span>
					<br />
				</h1>
				<p class="lead">Please allow location and refresh your page!</p>
				<br />
				<br />
			</div>
		</div>
		<div *ngIf="productFound">
			<!--<button type="button" class="mr-4 btn btn-lg btn-rounded pink-gradient text-uppercase px-4 mb-4">Label</button>
            	<button type="button" class="ml-4 btn btn-lg btn-rounded pink-gradient text-uppercase px-4 mb-4" >Leaflet</button>-->
			<!-- <button type="button" *ngIf="!productRegister" class=" ml-2 mr-2 btn btn-lg btn-rounded pink-gradient text-uppercase px-4 mb-4" (click)="wantRegister()" >Register Product</button> -->
			<!-- <button type="button" *ngIf="productRegister == false" (click)="get_rewards()" class="ml-2 mr-2 btn btn-lg btn-rounded pink-gradient text-uppercase px-4 mb-4">Rewards</button> -->
		</div>
	</div>

	<div class="m-0 p-0 main row-margin-0" *ngIf="productFound">
		<div class="justify-content-center mt-0 row-margin-0">
			<div class="row row-margin-0 productname">
				<div class="col-12 text-center mt-3">
					{{prodDetails.name}}
				</div>
			</div>

			<div class="row row-margin-0 m-0 p-0">
				<div class="col-12 text-center p-0 mt-3">
					<!-- <img class="img" src="{{prodDetails.main_image}}"> -->
					<img src="{{helper.image_url +'product/' + prodDetails.main_image}}" class="d-block" style="width: 70%; display: block; margin: 0 auto;" alt="...">

					<!-- <div id="carouselExampleIndicators" style="max-width: 250px;margin: auto;" class="carousel slide"
						data-ride="carousel">
						<ol class="carousel-indicators">
							<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
							<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
							<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
						</ol>
						<div class="carousel-inner">
							<div class="carousel-item active">
								<img src="{{prodDetails.main_image}}" class="d-block w-100" alt="...">
							</div>
							<div class="carousel-item" *ngIf='prodDetails.images.length>0'>
								<img src="{{prodDetails.images[0]}}" class="d-block w-100" alt="...">
							</div>
							<div class="carousel-item" *ngIf='prodDetails.images.length>1'>
								<img src="{{prodDetails.images[1]}}" class="d-block w-100" alt="...">
							</div>
							<div class="carousel-item" *ngIf='prodDetails.images.length>2'>
								<img src="{{prodDetails.images[2]}}" class="d-block w-100" alt="...">
							</div>
							<div class="carousel-item" *ngIf='prodDetails.images.length>3'>
								<img src="{{prodDetails.images[3]}}" class="d-block w-100" alt="...">
							</div>
						</div>
						<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
							data-slide="prev">
							<span class="carousel-control-prev-icon" style="color:black" aria-hidden="true"></span>
							<span class="sr-only">Previous</span>
						</a>
						<a class="carousel-control-next black" href="#carouselExampleIndicators" role="button"
							data-slide="next">
							<span class="carousel-control-next-icon" style="color: black;" aria-hidden="true"></span>
							<span class="sr-only">Next</span>
						</a>
					</div> -->
				</div>


			</div>

			<div class="row row-margin-0 mt-3"
				*ngIf="prodDetails.is_already_purchase==false && prodDetails.purchased_by_other==false">
				<div class="col-12 text-center" style="align-items: center;">
					<div class="success text-center m-auto p-2">
						<div style="font-size: 16px;">Woohoo!</div>
						<div style="font-size: 14px;opacity: 80%;">This product is genuine & registered to your contact
							number</div>
					</div>
				</div>
			</div>

			<div class="row row-margin-0 mt-3"
				*ngIf="prodDetails.is_already_purchase==true && prodDetails.purchased_by_other==false">
				<div class="col-12 text-center" style="align-items: center;">
					<div class="registered text-center m-auto p-3">
						<div style="font-size: 16px;">Hi!</div>
						<div style="font-size: 14px;opacity: 80%;">This product is already been registered by you.</div>
					</div>
				</div>
			</div>

			<div class="row row-margin-0 mt-3" *ngIf="prodDetails.purchased_by_other==true">
				<div class="col-12 text-center" style="align-items: center;"
					routerLink='/user/report/{{prodDetails.product_id}}/{{prodCode}}'>
					<div class="report text-center m-auto p-2">
						<div style="font-size: 16px;">Oops!</div>
						<div style="font-size: 14px;opacity: 80%;">This product is already registered.</div>
						<div style="font-size: 14px;opacity: 80%;">Click here to report</div>

					</div>
				</div>
			</div>

			<hr class="mb-3 pink-gradient" style="width: 30%;height: 2px;">

			<!-- <div class="row row-margin-0 mt-3 ">
			<div class="col-6 d-flex text-center p-0 m-0">
				<div class="col-3 m-0 p-0"><span>Batch No:</span><span>AT Gold</span></div>
				<div class="col-3 m-0 p-0"><span>Exp. Date:</span><span>02-Aug-201</span></div>
			</div>
		</div> -->

			<div class="row row-margin-0 justify-content-center" style="font-size: 14px;">
				<div class="col-6 text-right">
					<span style="color: #8E8E8E;">Batch No.: </span><span
						style="font-weight: 500;">{{prodDetails.batch_no}}</span>
				</div>
				<div class="col-6">
					<span style="color: #8E8E8E;">Exp. Date: </span><span
						style="font-weight: 500;">{{prodDetails.expiry_date | date: "dd-MMM-yyyy"}}</span>
				</div>
			</div>

			<hr class="mb-2 mx-6 divide">

			<div class="row row-margin-0 justify-content-center text-center ">
				<div style="width: 100%;max-width: 400px;padding: 0px;">

					<div class="tabbable-panel m-0 p-0">
						<div class="tabbable-line m-0 p-0">
							<ul class="nav nav-tabs m-0 p-0">
								<div style="display: inline-flex;width:100%">
									<div class="col-6 nav-item m-0 p-0 navtext" [ngClass]="{'active':type== 1}">
										<!-- <li class="nav-item"  [ngClass]="{'active':type== 1}"> -->
										<a class="nav-link" [ngClass]="{'active':type== 1}" href="javascript:void(0)"
											(click)="type=1"><span>About</span></a>
										<!-- </li> -->
									</div>
									<div class="col-6 m-0 p-0">
										<li class="nav-item" [ngClass]="{'active':type== 2}">
											<a class="nav-link" [ngClass]="{'active':type== 2}"
												href="javascript:void(0)" (click)="type=2"><span>How to
													use</span></a>
										</li>
									</div>
								</div>
							</ul>

							<!-- <div class="col-6 nav nav-tabs" style="border:1px solid;display: inline-flex;">
							<div class="nav-item" [ngClass]="{'active':type== 1}">
								<div class="nav-link" [ngClass]="{'active':type== 1}" (click)="type=1">
									Here type 1
								</div>
							</div>

							<div class="nav-item" [ngClass]="{'active':type== 2}">
								<div class="nav-link" [ngClass]="{'active':type== 2}" (click)="type=2">
									Here type 2
								</div>
							</div>
						</div> -->
						</div>
					</div>

					<div class="skybg p-4">
						<div class="mt-0 aboutus" [hidden]="type != 1">
							<div class="aboutustitle">
								About Product
							</div>
							<div class="para mt-3">
								{{prodDetails.feature_value}}
							</div>

						</div>
						<div class="text-center mt-3" [hidden]="type == 1">
							<div class="row row-margin-0 justify-content-center text-center" style="font-size: 14px;">
								<div class="para">
									<div id="vedio1"></div>
									<!-- <div *ngIf="prodDetails.detail_value">
										<iframe class="mt-4" width="100%" src="{{prodDetails.detail_value}}" allowfullscreen></iframe>
									</div> -->
									<!-- <iframe src="https://www.youtube.com/embed/YCUmskTBAtU"></iframe> -->
									<!-- <img class="offer-tag" src="../../assets//img/offer-tag.png">
									<img class="offer-card-img" style="width:100%;height: 130px;"
										src="../../assets/img/offer-card-2.png">
									<span class="offer-product">Attila Gold</span>
									<span class="offer-point">500</span>
									<span class="offer-point-text">Points</span> -->
								</div>
							</div>

							<!-- <div class="row row-margin-0 justify-content-center text-center" style="font-size: 14px;">
								<div class="col-12 p-0 m-0 offer-card">
									<img class="offer-tag" src="../../assets//img/offer-tag.png">
									<img class="offer-card-img" style="width:100%;height: 130px;"
										src="../../assets/img/offer-card-2.png">
									<span class="offer-product">Attila Gold</span>
									<span class="offer-point">500</span>
									<span class="offer-point-text">Points</span>
				
								</div>
							</div> -->
						</div>
					</div>

				</div>
			</div>

			<!-- Offers -->
			<div class="row row-margin-0 justify-content-center text-center" style="font-size: 14px;"
				*ngIf="loyaltyPoints && loyaltyPoints.length>0;">
				<div class="col-12 p-3" style="max-width: 400px;">
					<span class="titletxt float-left">Offers</span>
					<span routerLink="/user/offers" style="font-size:14px;color:#C069D5;float:right">View All</span>
				</div>
			</div>


			<div class="row row-margin-0 justify-content-center text-center" style="font-size: 14px;">
				<div id="carouselExampleIndicators0" style="max-width: 300px;margin: auto;" class="carousel slide"
					data-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item active" [ngClass]="{'active':i==0}"
							*ngFor="let data of offers;index as i">
							<div>
								<img src={{data.image}} class="offer-image">
							</div>
						</div>
					</div>
					<a class="carousel-control-prev" href="#carouselExampleIndicators0" role="button" data-slide="prev">
						<span class="carousel-control-prev-icon" style="color:black" aria-hidden="true"></span>
						<span class="sr-only">Previous</span>
					</a>
					<a class="carousel-control-next black" href="#carouselExampleIndicators0" role="button"
						data-slide="next">
						<span class="carousel-control-next-icon" style="color: black;" aria-hidden="true"></span>
						<span class="sr-only">Next</span>
					</a>
				</div>
			</div>

			<!-- Rewards -->
			<div class="row row-margin-0 justify-content-center text-center" style="font-size: 14px;"
				*ngIf="loyaltyPoints && loyaltyPoints.length>0;">
				<div class="col-12 p-3" style="max-width: 400px;">
					<span class="titletxt float-left">Rewards</span>
					<span routerLink="/user/offers" [queryParams]='{lp:true}'
						style="font-size:14px;color:#C069D5;float:right;">View All</span>
				</div>
			</div>

			<div class="row row-margin-0 justify-content-center text-center" style="font-size: 14px;"
				*ngIf="loyaltyPoints && loyaltyPoints.length>0;">
				<div id="carouselExampleIndicators1" style="width: 94%;" class="carousel slide"
					data-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item active" [ngClass]="{'active':i==0}"
							*ngFor="let data of loyaltyPoints;index as i" style="height: 136px;">
							<div class="row col-12 pl-0 pr-0 ml-0 mr-0 mb-0">
								<div class="col-12 p-0" style="position: absolute;">
								<img class="offer-card-img" style="width:100%;height: 130px;"
									src="../../assets/img/offer-card-2.png">
								</div>
								<div class="col-3 pl-4" style="padding-top: 7%;">
									<img class="offer-tag-lp" src="../../assets/img/offer-tag.png">
								</div>
								<div class="col-5 text-left pr-0" style="padding-top: 13%;">
									<div class="offer-product-lp">{{data.product}}</div>
								</div>
								<div class="col-auto p-3">

									<div class="offer-point-lp">win upto<br><span style="font-size: x-large">{{data.max_points}}</span> <br>Points</div>

									<!-- <span class="offer-point-text-2">Points</span> -->
								</div>
							</div>
						</div>
					</div>
					<a class="carousel-control-prev" href="#carouselExampleIndicators1" role="button" data-slide="prev">
						<span class="carousel-control-prev-icon" style="color:black" aria-hidden="true"></span>
						<span class="sr-only">Previous</span>
					</a>
					<a class="carousel-control-next black" href="#carouselExampleIndicators1" role="button"
						data-slide="next">
						<span class="carousel-control-next-icon" style="color: black;" aria-hidden="true"></span>
						<span class="sr-only">Next</span>
					</a>
				</div>


			</div>


			<hr class="divide mt-3" style="height: 10px;">
			<div class="row row-margin-0 justify-content-center text-center">
				<div class="row row-margin-0 w-100">
					<div class="col-12 p-3">
						<span class="titletxt">Share Feedback</span>
					</div>
				</div>
			</div>

			<div class="row row-margin-0 justify-content-center text-center m-0 p-0">
						<span class="rateus">Rate us</span>
				<div class="row row-margin-0 w-100">
					<div class="col-12 p-3">
						<div style="display: inline-block;">
							<ngx-star-rating width="7" [(ngModel)]="rating" [id]="'rating1'"></ngx-star-rating>
						</div>

						<div class="row mt-6">

							<div class="col-12 row-margin-0">
								<div class="form-group text-center">
									<input type="text" class="form-control" [(ngModel)]="comments" id="usr" required>
									<label for="usr">Comment</label>
								</div>
							</div>
						</div>

						<div class="col-12 p-3 text-center" *ngIf="comments == '' || comments == null">
							<a href="javascript:void(0);" (click)="feedback()"
								class="btn btn-rounded pink-gradient btn-submit">
								<span class=""> Submit</span></a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<hr style="height: 10px;">

		<div class="row row-margin-0 justify-content-center text-center">
			<div class="row row-margin-0 w-100">
				<div class="col-12 p-3">
					<span class="titletxt">Customer Care</span>
				</div>
			</div>
		</div>

		<div class="col-12 row-margin-0 justify-content-center w-100">
				<div class="row">
					<div class="col-1"></div>
					<div class="col-10">
						<table>
							<tr>
								<td style="color: #909090;">Email: </td>
								<td>{{(prodDetails) ? prodDetails.care_email : "-"}}</td>
							</tr>
							<tr>
								<td style="color: #909090;">Phone: </td>
								<td>{{(prodDetails) ? prodDetails.care_phone : "-"}}</td>
							</tr>
							<tr>
								<td style="color: #909090;">Address: </td>
								<td>{{(prodDetails) ? prodDetails.care_address : "-"}}</td>
							</tr>
							<tr>
								<td style="color: #909090;">Marketed By: </td>
								<td>{{(prodDetails) ? prodDetails.marketed_by : "-"}}</td>
							</tr>
						</table>
					</div>
					<div class="col-1"></div>
				</div>
		</div>
		<div style="height: 100px;"></div>

	</div>

	<!-- Modal Login -->
	<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-hidden="true">
		<button type="button" class="close" data-dismiss="modal" id="login_close" aria-label="Close"
			style="display: none;">
			<span aria-hidden="true">×</span>
		</button>
		<div class="modal-dialog  modal-sm modal-dialog-centered" role="document">
			<div class="modal-content box-shadow pink-gradient">
				<div class="modal-header border-0  px-0">
					<div class="col-6">
						<h5 class="card-title mb-0">Login</h5>
					</div>
				</div>
				<div class="modal-body text-center pr-4 pl-4">
					<figure class="avatar avatar-120 mx-auto">
						<img src="assets/images/logo-150.png" alt="user image">
					</figure>
					<!--<h5 class="my-3 f-light">John McMohan</h5>-->
					<div class="input-group mb-2 mt-4">
						<div class="input-group-append">
							<select class="custom-select btn-block btn btn-outline-light"
								style="width: 35%;border-radius: 4px 0px 0px 4px;">
								<option selected="">+91</option>
								<option value="1">+61</option>
								<option value="2">+1</option>
							</select>
							<input type="text" class="form-control border-light" placeholder="Phone Number"
								style="border: none;border-radius: 0px 4px 4px 0px;">
						</div>
					</div>
					<div class="input-group mb-2">
						<input type="text" class="form-control border-light" placeholder="Country"
							style="border: none;border-radius: 4px 4px 4px 4px;">
					</div>
					<div class="input-group mb-2">
						<input type="text" class="form-control border-light" placeholder="State"
							style="border: none;border-radius: 4px 4px 4px 4px;">
					</div>
					<div class="input-group mb-2">
						<input type="text" class="form-control border-light" placeholder="City"
							style="border: none;border-radius: 4px 4px 4px 4px;">
					</div>
					<div class="input-group mb-2">
						<input type="text" class="form-control border-light" placeholder="Pincode"
							style="border: none;border-radius: 4px 4px 4px 4px;">
					</div>
					<div class="input-group mb-2" *ngIf="verify_bool == 1">
						<select class="custom-select btn-block">
							<option selected="">User Type</option>
							<option value="1">Retailer</option>
							<option value="2">Distributor</option>
							<option value="2">Wholesaler</option>
						</select>
					</div>
					<div class="mb-4 mt-4">
						<img src="assets/images/captcha_small.jpg" style="width: 100%;" alt="Captcha image">
					</div>
					<div class="text-center mt-4">
						<button class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal"
							data-target="#otp_verification" (click)="login()">Login</button>
					</div>
				</div>
			</div>
		</div>
	</div>


	<!-- Modal OTP-->
	<div class="modal fade" id="otp_verification" tabindex="-1" role="dialog" aria-hidden="true">
		<button type="button" class="close" data-dismiss="modal" id="otp_close" aria-label="Close"
			style="display: none;">
			<span aria-hidden="true">×</span>
		</button>
		<div class="modal-dialog  modal-sm modal-dialog-centered " role="document">
			<div class="modal-content box-shadow pink-gradient">
				<div class="modal-header border-0  px-0">
					<div class="col-12">
						<h5 class="card-title mb-0">OTP Verification</h5>
					</div>
				</div>
				<div class="modal-body text-center pr-4 pl-4">
					<div id="recaptcha-container"></div>
					<div class="row row-margin-0 mb-2">
						<ng-otp-input style="width:100%" #ngOtpInput (onInputChange)="onOtpChange($event)"
							[config]="config"></ng-otp-input>
					</div>
					<div class="mb-2" *ngIf="otp_time!=0">
						{{otp_time}} sec remaining
					</div>
					<div class="text-center" *ngIf="otp_time==0">
						<button (click)="otp_verification()" class="mb-2 btn btn-light text-dark btn-rounded">Re-Send
							OTP</button>
					</div>
					<br>
					<!--<div class="text-center">
                                <button class="btn btn-circle btn-outline-light btn-block col" (click)="redirect_product()">Verify</button>
                            </div>
                            <br>-->
				</div>
			</div>
		</div>
	</div>

	<!-- Scratch card -->
	<div class="modal fade" id="card" tabindex="-1" role="dialog" aria-hidden="true">
		<button type="button" class="close" data-dismiss="modal" id="card_close" aria-label="Close"
			style="display: none;">
			<span aria-hidden="true">×</span>
		</button>
		<div class="modal-dialog justify-content-center modal-sm modal-dialog-centered" role="document">
			<div class="modal-content size-220 p-0">
				<div class="modal-body text-center p-0 m-0">
					<div class="container-fluid main-container row p-0 m-0">
						<div class="" style="width: 100%">
							<div id="js--sc--container">
								<div class="s__inner">
									<div style=" z-index: 9;background: white;padding: 26px;border-radius: 5px;">
										<div class="scratch-card-points">{{point}} Points</div>
										<img src="../../assets/img/4.png" class="fw-2x-220">
										<img src="../../assets/img/1.png" class="coins-01">
										<div class="points-won-2">You've Won</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<br>
				</div>
			</div>
		</div>
	</div>
	<button id="want_register_open" class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal"
		data-target="#want_register" style="display: none;">open</button>
	<button id="otp_open" class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal"
		data-target="#otp_verification" style="display: none;">open</button>
	<button id="card_open" class="btn btn-circle btn-outline-light btn-block col" data-toggle="modal"
		data-target="#card" style="display: none;">open</button>