<header class="main-header">
  <div class="container-fluid my-header m-0 p-0">
    <svg id="left-menu" class="three-line" style="float:left;margin-top:10px" xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
      <defs>
        <style>
          .a {
            fill: none;
            stroke: #fff;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
          }
        </style>
      </defs>
      <g transform="translate(-2 -5)">
        <line class="a" x2="18" transform="translate(3 12)" />
        <line class="a" x2="18" transform="translate(3 6)" />
        <line class="a" x2="18" transform="translate(3 18)" />
      </g>
    </svg>
    <!-- <div class="text-center my-head row"> -->
      <span class="my-head" *ngIf="router.url == '/dashboard'">Home</span>
      <span class="my-head" *ngIf="router.url == '/user/history'">My Purchases</span>
      <span class="my-head" *ngIf="router.url.includes('/user/offers')">Offers</span>
      <span class="my-head" *ngIf="router.url == '/user/wallet_point'">Wallet</span>
      <span class="my-head" *ngIf="router.url.includes('user/product')">Product</span>
      <span class="my-head" *ngIf="router.url == '/user/earn_points'">Earn Points</span>
      <span class="my-head" *ngIf="router.url.includes('user/report')">Report</span>


    <!-- </div> -->
    <!-- <img src="../../../assets/img/logo-2x.png" class="logo-img"> -->
    <!-- <svg class="float-right" xmlns="http://www.w3.org/2000/svg" width="20" height="21.996" viewBox="0 0 20 21.996"><defs><style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-2 -1)"><path class="a" d="M18,8A6,6,0,1,0,6,8c0,7-3,9-3,9H21s-3-2-3-9"/><path class="a" d="M13.73,21a2,2,0,0,1-3.46,0"/></g></svg> -->

  </div>
</header>