import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs-line',
  templateUrl: './tabs-line.component.html',
  styleUrls: ['./tabs-line.component.css']
})
export class TabsLineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  scroll(id){}
}
