import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TempStorage } from '../temp_storage';
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js';
import { ApiService } from "src/app/api-service/api-service.service";
import { HelperService } from "src/app/helper.service";
//import { FlashMessagesService } from "angular2-flash-messages";
import firebase from 'firebase';
import "jquery";
declare var $: any;
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.css"],
})

export class ProductComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  roleId;
  reward_id;
  otp_time = 40;
  interval = null;
  type: number = 1;
  reward_total: number = 0;
  point: number = 0;
  prodCode;
  prodDetails: any = {};
  shortcode_id: any = '';
  productFound = null;
  productRegister = false;
  latitude: any = null;
  longitude: any = null;
  is_reward = false;
  currentUserRole: any = localStorage.getItem("role_id");
  login_bool: number = 1;
  verify_bool: number = 2;
  user_purchased: boolean = false;
  loader: boolean = false;
  isLocation: boolean = false;
  offers: any;
  loyaltyPoints: any;
  consumer_id;
  stars = [];
  whiteStars = [];
  reviews:any = {};
  phone;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    containerStyles: {
      'display': 'block',
      'padding': '10px'
    },
    inputStyles: {
      'width': '30px',
      'height': '30px'
    }
  };
  rating: number;
  comments: string;
  safeUrl;
  videoflag = false;
  constructor(
    private _sanitizer :DomSanitizer, 
    private router: Router, 
    public temp_storage: TempStorage, activatedRoute: ActivatedRoute, private apiService: ApiService, public helper: HelperService) {
    activatedRoute.params.subscribe((params) => {
      this.prodCode = params["productCode"];
    });
    activatedRoute.queryParams.subscribe((params) => {
      let detail = params["detail"];
      if(detail == "1"){
        //this.checkScannedCodes(this.prodCode);
        this.getRewardTotal();
        this.getProductDetails();
        this.getOffers();
      }else{
        this.checkScannedCodes(this.prodCode);
      }
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
      } else {
        console.log("No user is signed in.");
      }
    });

    this.stars.length = 0
    this.whiteStars.length = 5;

    this.rating = 0;
    this.comments = '';
  }

  ngOnInit() {
    this.consumer_id = localStorage.getItem('customer_id');

    firebase.auth().useDeviceLanguage();
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {

      },
      'expired-callback': function () {
        // Response expired. Ask user to solve reCAPTCHA again.
        this.captchLogin = false;
      }
    });

    this.gps();
        
    
    
    // this.getPoints();
  }

  checkScannedCodes(code){
    var customer_id = localStorage.getItem('customer_id');
    //this.loader = true;
    this.apiService.get('user/checkScannedCodes?customer_id=' + customer_id + '&shortCode=' + code, false).subscribe((response: any) => {
      //this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.getRewardTotal();
        this.getProductDetails();
        this.getOffers();
        //this.router.navigate(['user/product/' + code]);
      }else {
        this.helper.toastr.error(response.body.message);
      }
    })
  }

  gps() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((Position) => {
        this.showPosition(Position);
      }, (err) => {
        if (err.code == 1) {
          this.helper.toastr.error('Please allow location and refresh your page');
        }
      });
    }
    else {
      this.helper.toastr.error("Geolocation is not supported by this browser.");
    }
  }


  showPosition(position) {
    if (position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.isLocation = true;
      // this.getProductDetails();
    } else {
      this.helper.toastr.error('Please allow location and refresh your page');
    }
  }

  login() {
    $('#login_close').click();
  }

  redirect_product() {
    this.temp_storage.is_login = true;
    $('#otp_close').click();
    this.createNewScratchCard();
  }

  onOtpChange(e) {
    if (e.length == 6) {
      this.submit_otp(e);
    }
  }

  wantRegister() {
    //this.is_reward=false;
    if (this.productRegister == false) {
      setTimeout(() => {
        $('#want_register_open').click();
      }, 2000)
      this.recaptchaRender();

    }
  }
  scratch_card() {
    $('#card_close').click();
    this.router.navigate(["user/wallet_point"]);
  }
  /* otp_verification(){
     this.temp_storage.is_login =true;
     this.login_bool = 2;
      $('#otp_close').click();
     this.router.navigate(["user/product"]);
   }*/

  getProductDetails() {
    this.loader = true;
    this.apiService.get('user/findByQrCode?unique_code=' + this.prodCode + '&consumer_id=' + localStorage.getItem("customer_id"), false).subscribe((response: any) => {
      this.loader = false;
      // var video_id = text.split('v=')[1].split('&')[0];
   
      // (<any>$('#video')).html('<iframe class="mt-4" width="100%" src="https://www.youtube.com/embed/YCUmskTBAtU"></iframe>');
      let video = document.getElementById("video");
      // video.innerHTML = '<iframe class="mt-4" width="100%" src="' + this.prodDetails.vlog + '"></iframe>'
      
      // $('#video').html('<iframe class="mt-4" width="100%" src="' + this.prodDetails.vlog + '"></iframe>')
      // $<any>('div.video').html('<iframe class="mt-4" width="100%" src="https://www.youtube.com/embed/YCUmskTBAtU"></iframe>');

      if (response.status == 200 && response.body.success == 1) {
        this.prodDetails = response.body.data
        // this.prodDetails.videoURL = "https://www.youtube.com/embed/YCUmskTBAtU";
        this.shortcode_id = this.prodDetails.trustedQrcodes_id
        console.log(this.shortcode_id);
        this.getCustomerReviews();
        this.prodDetails.is_already_purchase = response.body.is_already_purchase
        this.prodDetails.purchased_by_other = response.body.purchased_by_other
        console.log("proddetails",this.prodDetails)
        let text = this.prodDetails.detail_value

        if(text) {
          this.safeUrl = text;
          console.log('safe url: ', this.safeUrl)
        //   var video_id = text.split('v=')[1].split('&')[0];

        // this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+video_id)
        this.videoflag = true;
        }
        this.getPoints();
        setTimeout(() => {
          $('#html1').html(this.prodDetails.feature_value);
          if (this.prodDetails.detail_value) {
            $('#vedio1').html('<iframe width="100%" src="'+ this.prodDetails.detail_value + '" allowfullscreen></iframe>');
          }
        }, 400)
        this.productFound = true;
        this.prodDetails.isReview = response.body.isReview;
        if (response.body.customer_id == localStorage.getItem("customer_id")) {
          if (response.body.isReview) {
            this.rating = response.body.ratings;
            this.comments = response.body.comments;
          }
          this.user_purchased = true;
        } else {
          this.user_purchased = false;
        }
        if (this.temp_storage.is_detail_product) {
          this.submitScanHistory();
          this.temp_storage.is_detail_product = false;
        }
        if (response.body.is_already_purchase) {
          this.productRegister = response.body.is_already_purchase;
        }
      } else {
        this.productFound = false;
        this.productRegister = false;
      }
    },
      (error) => {
        this.loader = false;
        this.productFound = false;
        this.productRegister = false;
        console.error(error);
      })
  }
  getRewardTotal() {
    this.apiService.get('user/getRewardPointTotal', false).subscribe((response: any) => {
      if (response.status == 200 && response.body.success == 1) {
        this.helper.reward_total = response.body.total;
      }
    })
  }

  submitScanHistory() {
    var customer_id = localStorage.getItem('customer_id');
    this.apiService.post('user/scanProduct', {
      code_id: this.prodDetails.trustedQrcodes_id,
      brand_id: this.prodDetails.brand_id,
      customer_id: customer_id,
      product_id: this.prodDetails.product_id,
      shortCode: 'http://ttags.in/' + this.prodCode,
      latitude: this.latitude,
      longitude: this.longitude,
    }, null).subscribe((response: any) => {
      if (response.status == 200 && response.body.success == 1) {
        console.log("scan response", response.body);

      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  rewardsApply() {
    console.log("reward apply", this.prodDetails);
    this.apiService.post('user/addRewards', {
      code_id: this.prodDetails.trustedQrcodes_id,
      product_id: this.prodDetails.product_id,
      shortCode: 'http://ttags.in/' + this.prodCode
    }, null).subscribe((response: any) => {
      this.loader = false;
      console.log("reward apply response", response);
      this.scanQr();
      if (response.status == 200 && response.body.success == 1) {
        $('#otp_close').click();
        this.point = response.body.point;
        this.reward_id = response.body.rewards.id
        this.createNewScratchCard();
      } else if (response.status == 200 && response.body.success == 0) {
        this.helper.toastr.error("loyalty points not available on this product");
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  getPoints() {
    this.loader = true;
    var customer_id = localStorage.getItem('customer_id');

    this.apiService.post('user/addProductRegister', {
      code_id: this.prodDetails.trustedQrcodes_id,
      brand_id: this.prodDetails.brand_id,
      customer_id: customer_id,
      product_id: this.prodDetails.product_id,
      shortCode: 'http://ttags.in/' + this.prodCode,
      latitude: this.latitude,
      longitude: this.longitude

    }, null).subscribe((response: any) => {
      this.loader = false;
      console.log("scan resp", response.body);

      if (response.status == 200 && response.body.success == 1) {
        // $('#otp_close').click();
        //this.router.navigate(["user/history"]);
        this.rewardsApply()
        // this.rewardsApply();
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  scanQr() {
    this.apiService.post('user/add-scratch-cards', {
      consumer_id: localStorage.getItem('customer_id'),
      qr_code: this.prodCode,
    }, null).subscribe((response: any) => {
      console.log("scanqr res", response.body);
      if (response.status == 200 && response.body.success == 1) {

      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }


  submit_otp(e) {
    this.loader = true;
    var customer_id = localStorage.getItem('customer_id');

    this.apiService.post('user/addProductRegister', {
      code_id: this.prodDetails.trustedQrcodes_id,
      brand_id: this.prodDetails.brand_id,
      customer_id: customer_id,
      product_id: this.prodDetails.product_id,
      shortCode: 'http://ttags.in/' + this.prodCode
    }, null).subscribe((response: any) => {
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        // $('#otp_close').click();
        //this.router.navigate(["user/history"]);

        // this.rewardsApply();
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })


    window.confirmationResult.confirm(e).then((result) => {
      if (result.user) {
        // User signed in successfully.
        var customer_id = localStorage.getItem('customer_id');

        // this.apiService.post('user/addProductRegister',{
        //   code_id:this.prodDetails.trustedQrcodes_id,
        //   brand_id:this.prodDetails.brand_id,
        //   customer_id:customer_id,
        //   product_id:this.prodDetails.product_id,
        //   shortCode: 'http://ttags.in/'+this.prodCode
        // },null).subscribe((response: any) => {
        //   this.loader=false;
        //   if(response.status == 200 && response.body.success == 1){
        //     $('#otp_close').click();              
        //     this.rewardsApply();
        //   }
        // },
        //   (error) => {
        //     this.loader = false;
        //     console.log(error);
        //   })

      } else {
        this.loader = false;
        this.helper.toastr.error(' Invalid OTP');
      }
    }).catch((error) => {
      console.log(error);
      this.loader = false;
      this.helper.toastr.error(' Invalid OTP');
      // User couldn't sign in (bad verification code?)
      // ...
    });
  }

  scannedRewards() {
    this.loader = true;
    this.apiService.get('user/scannedRewards?customer_id=' + localStorage.getItem("customer_id") + '&product_id=' + this.prodDetails.product_id + '&code_id=' + this.prodDetails.trustedQrcodes_id, false).subscribe((response: any) => {
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.helper.toastr.error("This Reward is Already Scratched");
      } else {
        this.ngOtpInputRef.setValue("");
        this.otp_verification();
        $('#otp_open').click();
        this.recaptchaRender();
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  get_rewards() {
    this.is_reward = true;
    this.scannedRewards();
  }

  recaptchaRender() {

    const recaptchaVerifier = window.recaptchaVerifier;
    // [START auth_phone_recaptcha_render]
    recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
    // [END auth_phone_recaptcha_render]
  }
  product_register() {

    this.ngOtpInputRef.setValue("");
    this.otp_verification();
    $('#want_register_close').click();

  }
  otp_verification() {
    this.loader = true;
    const appVerifier = window.recaptchaVerifier;
    this.otp_time = 40;
    firebase.auth().signInWithPhoneNumber(localStorage.getItem('country_code') + localStorage.getItem('customer_phone'), appVerifier).then((confirmationResult) => {
      this.loader = false;
      this.interval = setInterval(() => {
        this.otp_time = this.otp_time - 1;
        if (this.otp_time == 0) {
          if (this.interval) {
            clearInterval(this.interval);
          }
        }
      }, 1000);

      // SMS sent. Prompt user to type the code from the message, then sign the
      window.confirmationResult = confirmationResult;

    }).catch((error) => {
      console.log(error);
    });
  }

  updateReward() {
    this.apiService.put('user/updateRewards', {
      customer_id: localStorage.getItem("customer_id"),
      reward_id: this.reward_id
    }, false).subscribe((response: any) => {
      console.log(response);
      if (response.status == 200 && response.body.success == 1) {
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  createNewScratchCard() {
    const scContainer = document.getElementById('js--sc--container')
    const sc = new ScratchCard('#js--sc--container', {
      scratchType: SCRATCH_TYPE.CIRCLE,
      containerWidth: 220,//scContainer.offsetWidth,
      containerHeight: 220,
      imageForwardSrc: './assets/img/you-have-reward.jpg',
      //imageBackgroundSrc: './assets/images/scratchcard-background.svg',
      htmlBackground: '',
      clearZoneRadius: 40,
      nPoints: 30,
      pointSize: 4,
      callback: () => {
        this.updateReward();
        setTimeout(() => {
          this.scratch_card();
        }, 3000)
      }
    })
    // Init
    sc.init().then(() => {
      sc.canvas.style.position = "absolute";
      sc.canvas.style.top = "0px";
      sc.canvas.style.left = "0px";
      sc.canvas.style['z-index'] = "99";
      sc.canvas.style['border-radius'] = "5px";

      $('#card_open').click();
      sc.canvas.addEventListener('scratch.move', () => {
        let percent = sc.getPercent().toFixed(2)
      })
    }).catch((error) => {
      // image not loaded
      alert(error.message);
    });;

  }

  scroll(id) {
    let el = document.getElementById(id);
    console.log("elll", el, id);

    el.scrollIntoView()
  }
  feedback() {
    let customer_id = localStorage.getItem("customer_id");
    this.loader = true;
    if(this.rating<=0){
      this.helper.toastr.error("Atleast 1 star required");
      this.loader = false;
      return;
    }    
    this.apiService.post('user/addCustomerReviews', {
      customer_id: customer_id,
      customer_product_id: this.shortcode_id,
      ratings: this.rating,
      comments: this.comments
    }, null).subscribe((response: any) => {
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.prodDetails.isReview = true;
        this.helper.toastr.success(response.body.message);
      } else {
        this.helper.toastr.error(response.body.message);
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  getOffers() {
    this.apiService.get('user/offers?consumer_id=' + localStorage.getItem("customer_id"), false).subscribe((response: any) => {
      console.log("offer res", response.body);
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.offers = response.body.data.luckyDraw;
        this.loyaltyPoints = response.body.data.pointAllocation;
      }
    },
      (error) => {
        this.loader = false;
        console.error("err", error);
      })
  }

  getCustomerReviews() {
    this.apiService.get('user/getCustomerReview?customer_id=' + localStorage.getItem("customer_id") + "&customer_product_id=" + this.shortcode_id, false).subscribe((response: any) => {
        console.log(response.body);
      if (response.status == 200 && response.body.success == 1) {
          this.rating = response.body.reviews.ratings;
          this.comments = response.body.reviews.comments;
      }
    },
      (error) => {
        console.error("err", error);
      })
  }
}
