import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
	public reward_total =0;
	public payTmAmountTotal = 0;
	loading:boolean=false;
	image_url= environment.imageUrl;
  constructor(public toastr:ToastrService) { }

}



