import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "./guards/auth-guard.service";
import { HomePageComponent } from './home_page/home.component';
import { WalletPointComponent } from './wallet_point/wallet_point.component';
import { HistoryComponent } from './history/history.component';
import { ProductComponent } from './product/product.component';
import { ReportComponent } from './report/report.component';
import { OffersComponent } from './offers/offers.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EarnPointsComponent } from './earn-points/earn-points.component';


const routes: Routes = [
  //{ path: "", redirectTo: "", pathMatch: "full" },
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Home",
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Dashboard",
    }
  },
  {
    path: 'user/wallet_point',
    component: WalletPointComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::WalletPoint",
    }
  },
  {
    path: 'user/history',
    component: HistoryComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::History",
    }
  },
  {
    path: 'user/product/:productCode',
    component: ProductComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Product",
    }
  },
  {
    path: 'user/report/:id/:code',
    component: ReportComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Report",
    }
  },
  {
    path: 'user/offers',
    component: OffersComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Offers",
    }
  },
  {
    path: 'user/earn_points',
    component: EarnPointsComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Trusttags::Earn Points"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
