<!-- <div class="sidebar sidebar-left">
  <ul class="nav flex-column">
    <li class="nav-item">
      <a
        href="javascript:void(0);"
        [routerLink]="'/user/wallet_point'"
        routerLinkActive="active"
        class="nav-link pink-gradient-active"
        ><i class="fas fa-boxes icon"></i> <span>Wallet points</span></a
      >
    </li>
    <li class="nav-item">
      <a
        href="javascript:void(0);"
        [routerLink]="'/user/history'"
        routerLinkActive="active"
        class="nav-link pink-gradient-active"
        ><i class="fas fa-boxes icon"></i> <span>History</span></a
      >
    </li>
  </ul>
</div> -->

<!-- <div class="overlay"></div> -->

<div class="sidebar sidebar-left m-0 pt-0">
  <div style="background: #52539F14;" class="p-3">
    <div class="row">
      <div class="col-12">
        <svg id="left-menu-2" style="float:right" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
          viewBox="0 0 22 22">
          <defs>
            <style>
              .cl {
                fill: none;
                stroke: #909090;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 2px;
              }
            </style>
          </defs>
          <g transform="translate(-1 -1)">
            <circle class="cl" cx="10" cy="10" r="10" transform="translate(2 2)" />
            <line class="cl" x1="6" y2="6" transform="translate(9 9)" />
            <line class="cl" x2="6" y2="6" transform="translate(9 9)" />
          </g>
        </svg>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center userimg">
        <img class="img" src="../../../assets/img/trusttags-logo.png">
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center username">
        <span style="font-family: 'Poppins ';font-size: 18px;color:#424242" *ngIf="temp_storage.first_name!='undefined'">
          {{temp_storage.first_name}}
        </span>
        <span *ngIf="temp_storage.customer_phone != ''" style="font-family: 'Poppins ';font-size: 18px;color:#424242">
          {{temp_storage.customer_phone}}
        </span>   
      </div>
    </div>
  </div>

  <div class="tabs p-0 m-0" style="font-family: 'Poppins ';">
    <ul class="nav" style="display:block">
      <li class="nav-item item toggle-menu"  routerLink="/dashboard" routerLinkActive="active" >
        <a href="javascript:void(0);" class="item">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.2" viewBox="0 0 24 24.2">
            <defs>
              <style>
                .a {
                  fill: rgba(255, 255, 255, 0);
                }

                .b,
                .c {
                  fill: #3224ae;
                }

                .c {
                  stroke: #3224ae;
                  stroke-width: 0.1px;
                }

                .d,
                .e {
                  stroke: none;
                }

                .e {
                  fill: #3224ae;
                }

                .f {
                  fill: none;
                }
              </style>
            </defs>
            <g transform="translate(-2246 -282.9)">
              <rect class="a" width="24" height="24" transform="translate(2246 283)" />
              <g transform="translate(2238.1 278.287)">
                <g class="b" transform="translate(-4.344 -44.639)">
                  <path class="d"
                    d="M 14.88882446289062 73.40164947509766 C 14.8888111114502 73.40164947509766 14.88881492614746 73.40164947509766 14.88880157470703 73.40164947509766 C 14.83437156677246 73.40164947509766 14.77976131439209 73.39263916015625 14.72647094726562 73.37487030029297 C 14.44776153564453 73.28197479248047 14.29977130889893 72.99818420410156 14.38956165313721 72.72880554199219 L 16.47110176086426 66.48419189453125 L 16.48249053955078 66.45000457763672 L 16.51853179931641 66.45000457763672 L 17.51767158508301 66.45000457763672 L 17.58723068237305 66.45000457763672 L 17.56506156921387 66.51593017578125 L 15.37250137329102 73.0380859375 C 15.29890632629395 73.25889587402344 15.10902786254883 73.40164947509766 14.88882446289062 73.40164947509766 Z" />
                  <path class="e"
                    d="M 14.88880634307861 73.35165405273438 C 15.08413410186768 73.35166168212891 15.25827026367188 73.22268676757812 15.32511138916016 73.02215576171875 L 17.51767158508301 66.5 L 16.51853179931641 66.5 L 14.43699169158936 72.74461364746094 C 14.35373115539551 72.99441528320312 14.4925012588501 73.24418640136719 14.74228096008301 73.32744598388672 C 14.79154109954834 73.34386444091797 14.84082412719727 73.35164642333984 14.88880634307861 73.35165405273438 M 14.88882160186768 73.45165252685547 C 14.82901096343994 73.45165252685547 14.76907157897949 73.44178009033203 14.71066093444824 73.42230987548828 C 14.40535163879395 73.32054138183594 14.24347114562988 73.00897216796875 14.34212112426758 72.71298980712891 L 16.44645118713379 66.40000152587891 L 17.65679168701172 66.40000152587891 L 15.41990089416504 73.05401611328125 C 15.340651512146 73.29175567626953 15.12720108032227 73.45165252685547 14.88882160186768 73.45165252685547 Z" />
                </g>
                <g class="b" transform="translate(-33.242 0)">
                  <path class="d"
                    d="M 56.06058883666992 7.090565204620361 L 55.99121856689453 7.090565204620361 L 55.01982879638672 7.090565204620361 L 54.98397827148438 7.090565204620361 L 54.97246932983398 7.056604862213135 L 54.3896484375 5.335865020751953 C 54.34526824951172 5.202714920043945 54.35629653930664 5.063075065612793 54.42063903808594 4.942435264587402 C 54.48376846313477 4.824065208435059 54.59238815307617 4.734255313873291 54.72648620605469 4.689565181732178 C 54.77977752685547 4.671794891357422 54.83439636230469 4.662785053253174 54.88881683349609 4.662785053253174 C 55.10903930664062 4.662785053253174 55.29891586303711 4.805544853210449 55.37255859375 5.026474952697754 L 56.03864669799805 7.024755001068115 L 56.06058883666992 7.090565204620361 Z" />
                  <path class="e"
                    d="M 54.88881683349609 4.712785243988037 C 54.84082412719727 4.712782859802246 54.79157257080078 4.720568656921387 54.7422981262207 4.736995220184326 C 54.49250793457031 4.820255279541016 54.35373687744141 5.070034980773926 54.43700790405273 5.319825172424316 L 55.01982879638672 7.040565013885498 L 55.99121856689453 7.040565013885498 L 55.32512664794922 5.042284965515137 C 55.25829315185547 4.841770172119141 55.08412551879883 4.712794303894043 54.88881683349609 4.712785243988037 M 54.88881683349609 4.612784862518311 L 54.88882827758789 4.612784862518311 C 55.1272087097168 4.612794876098633 55.34066772460938 4.772695064544678 55.41999816894531 5.010664939880371 L 56.12995910644531 7.140564918518066 L 54.94811630249023 7.140564918518066 L 54.3422966003418 5.35190486907959 C 54.29344940185547 5.205385208129883 54.30565643310547 5.051764965057373 54.37651824951172 4.918905258178711 C 54.44569778442383 4.789185047149658 54.56437683105469 4.690895080566406 54.71067810058594 4.642125129699707 C 54.76905822753906 4.622654914855957 54.82899856567383 4.612784862518311 54.88881683349609 4.612784862518311 Z" />
                </g>
                <g class="b" transform="translate(-47.755 -44.639)">
                  <path class="d"
                    d="M 77.10111999511719 73.40164947509766 C 76.88091278076172 73.40164184570312 76.69103240966797 73.25888061523438 76.61739349365234 73.0379638671875 L 74.45256805419922 66.51575469970703 L 74.43074035644531 66.45000457763672 L 74.50002288818359 66.45000457763672 L 75.47141265869141 66.45000457763672 L 75.5074462890625 66.45000457763672 L 75.51883697509766 66.48419189453125 L 77.60037994384766 72.72880554199219 C 77.69014739990234 72.99819183349609 77.54215240478516 73.281982421875 77.26345062255859 73.37487030029297 C 77.21015167236328 73.39263916015625 77.15553283691406 73.40164947509766 77.10111999511719 73.40164947509766 Z" />
                  <path class="e"
                    d="M 77.10111999511719 73.35165405273438 C 77.14911651611328 73.35165405273438 77.1983642578125 73.3438720703125 77.24764251708984 73.32744598388672 C 77.49742889404297 73.24418640136719 77.63619232177734 72.99441528320312 77.55294799804688 72.74461364746094 L 75.47141265869141 66.5 L 74.50002288818359 66.5 L 76.66481781005859 73.02215576171875 C 76.73166656494141 73.22267150878906 76.90581512451172 73.35164642333984 77.10111999511719 73.35165405273438 M 77.10111999511719 73.45165252685547 L 77.10111236572266 73.45165252685547 C 76.86273193359375 73.45164489746094 76.64927673339844 73.29174041748047 76.5699462890625 73.05377197265625 L 74.36145782470703 66.40000152587891 L 75.54348754882812 66.40000152587891 L 77.64781951904297 72.71298980712891 C 77.74645233154297 73.00897979736328 77.58455657958984 73.32054901123047 77.27925872802734 73.42230987548828 C 77.22087860107422 73.44178009033203 77.16094207763672 73.45165252685547 77.10111999511719 73.45165252685547 Z" />
                </g>
                <g class="b" transform="translate(-22.685 -0.072)">
                  <path class="d"
                    d="M 40.80743789672852 7.218311309814453 L 40.77139663696289 7.218311309814453 L 39.80000686645508 7.218311309814453 L 39.73093795776367 7.218311309814453 L 39.75250625610352 7.15269136428833 L 40.41859817504883 5.12666130065918 C 40.49231719970703 4.90554141998291 40.68220901489258 4.762781620025635 40.90242767333984 4.762781620025635 C 40.95683670043945 4.762781620025635 41.01145935058594 4.771791458129883 41.06474685668945 4.78956127166748 C 41.34345626831055 4.882461547851562 41.49144744873047 5.166251659393311 41.40165710449219 5.435631275177002 L 40.81882858276367 7.184121608734131 L 40.80743789672852 7.218311309814453 Z" />
                  <path class="e"
                    d="M 40.90242385864258 4.812786102294922 C 40.70709609985352 4.812780857086182 40.5329475402832 4.94175910949707 40.46609878540039 5.142281532287598 L 39.80000686645508 7.168311595916748 L 40.77139663696289 7.168311595916748 L 41.35422897338867 5.419821262359619 C 41.4374885559082 5.170031547546387 41.2987174987793 4.920251369476318 41.0489387512207 4.836991310119629 C 40.99967956542969 4.820572376251221 40.95040512084961 4.812787532806396 40.90242385864258 4.812786102294922 M 40.90242767333984 4.712781429290771 L 40.90244674682617 4.712781429290771 C 40.9622688293457 4.712791442871094 41.02218627929688 4.72266149520874 41.08055877685547 4.74212121963501 C 41.38586807250977 4.843891620635986 41.54774856567383 5.155461311340332 41.4490966796875 5.451441287994385 L 40.84347915649414 7.268311500549316 L 39.66186904907227 7.268311500549316 L 40.37109756469727 5.111051559448242 C 40.45056915283203 4.872671604156494 40.66403961181641 4.712781429290771 40.90242767333984 4.712781429290771 Z" />
                </g>
                <g class="b" transform="translate(0 -6.854)">
                  <path class="d"
                    d="M 31.54121780395508 28.5154972076416 L 31.49121856689453 28.5154972076416 L 8.399997711181641 28.5154972076416 L 8.349998474121094 28.5154972076416 L 8.349998474121094 28.46549606323242 L 8.349998474121094 14.20000648498535 L 8.349998474121094 14.15000629425049 L 8.399997711181641 14.15000629425049 L 31.49121856689453 14.15000629425049 L 31.54121780395508 14.15000629425049 L 31.54121780395508 14.20000648498535 L 31.54121780395508 28.46549606323242 L 31.54121780395508 28.5154972076416 Z M 8.977328300476074 27.86041641235352 L 30.91388893127441 27.86041641235352 L 30.91388893127441 14.7773265838623 L 8.977328300476074 14.7773265838623 L 8.977328300476074 27.86041641235352 Z" />
                  <path class="e"
                    d="M 31.49121856689453 28.46549606323242 L 31.49121856689453 14.20000648498535 L 8.399997711181641 14.20000648498535 L 8.399997711181641 28.46549606323242 L 31.49121856689453 28.46549606323242 M 8.927328109741211 14.72732639312744 L 30.96388816833496 14.72732639312744 L 30.96388816833496 27.91041564941406 L 8.927328109741211 27.91041564941406 L 8.927328109741211 14.72732639312744 M 31.59121894836426 28.56549644470215 L 8.29999828338623 28.56549644470215 L 8.29999828338623 14.10000610351562 L 31.59121894836426 14.10000610351562 L 31.59121894836426 28.56549644470215 Z M 9.027328491210938 27.81041717529297 L 30.86388778686523 27.81041717529297 L 30.86388778686523 14.82732677459717 L 9.027328491210938 14.82732677459717 L 9.027328491210938 27.81041717529297 Z" />
                </g>
                <g class="c" transform="translate(15.422 12.647)">
                  <rect class="d" width="1.499" height="6.134" />
                  <rect class="f" x="-0.05" y="-0.05" width="1.599" height="6.234" />
                </g>
                <g class="c" transform="translate(23.248 10.898)">
                  <rect class="d" width="1.499" height="7.882" />
                  <rect class="f" x="-0.05" y="-0.05" width="1.599" height="7.982" />
                </g>
                <g class="c" transform="translate(19.28 14.062)">
                  <rect class="d" width="1.499" height="4.718" />
                  <rect class="f" x="-0.05" y="-0.05" width="1.599" height="4.818" />
                </g>
              </g>
            </g>
          </svg> </a><span>Dashboard</span>
      </li>
      <li class="nav-item item toggle-menu"  routerLink="/user/wallet_point" routerLinkActive="active" >
        <a href="javascript:void(0);"class="item">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.2" height="20" viewBox="0 0 20.2 20">
            <defs>
              <style>
                .a {
                  fill: rgba(255, 255, 255, 0);
                }

                .b {
                  fill: #bababa;
                  stroke: #bababa;
                  stroke-width: 0.2px;
                }
              </style>
            </defs>
            <g transform="translate(-254.9 -761)">
              <rect class="a" width="20"  height="20" transform="translate(255 761)" />
              <path class="b"
                d="M10.951,966.362A1.958,1.958,0,0,0,9,968.313v13.9a1.714,1.714,0,0,0,1.707,1.707H27.293A1.714,1.714,0,0,0,29,982.216V971a1.714,1.714,0,0,0-1.707-1.707h-1.22V966.85a.507.507,0,0,0-.488-.488Zm0,.976H25.1v1.951H10.951a.976.976,0,0,1,0-1.951ZM9.976,970a1.935,1.935,0,0,0,.976.267c5.494,0,11.62,0,16.341,0a.72.72,0,0,1,.732.732v2.927H20.951a1.228,1.228,0,0,0-1.22,1.219v2.927a1.228,1.228,0,0,0,1.22,1.22h7.073v2.927a.72.72,0,0,1-.732.732H10.707a.72.72,0,0,1-.732-.732Zm10.976,4.9h7.073v3.415H20.951a.233.233,0,0,1-.244-.244v-2.927A.233.233,0,0,1,20.951,974.9Zm2.439.732a.976.976,0,1,0,.976.976A.976.976,0,0,0,23.39,975.63Z"
                transform="translate(246 -204.362)" />
            </g>
          </svg>
        </a><span>Wallet</span>
      </li>
      <li class="nav-item item toggle-menu" routerLink="/user/offers" routerLinkActive="active" >
        <a href="javascript:void(0);" class="item">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <defs>
              <style>
                .a {
                  fill: rgba(255, 255, 255, 0);
                }

                .b {
                  fill: #bababa;
                }
              </style>
            </defs>
            <g transform="translate(-225 -811)">
              <rect class="a" width="20"  height="20" transform="translate(225 811)" />
              <g transform="translate(207 793)">
                <path class="b"
                  d="M36.594,25.688l.125-2.344a.67.67,0,0,0-.344-.594l-2.094-1.062-1.062-2.094a.615.615,0,0,0-.594-.344l-2.344.125-1.969-1.281a.677.677,0,0,0-.687,0l-1.969,1.281-2.312-.125a.615.615,0,0,0-.594.344l-1.062,2.094L19.594,22.75a.67.67,0,0,0-.344.594l.125,2.344-1.281,1.969a.677.677,0,0,0,0,.688l1.281,1.969-.125,2.344a.67.67,0,0,0,.344.594l2.094,1.063,1.063,2.094a.615.615,0,0,0,.594.344l2.344-.125,1.969,1.281a.677.677,0,0,0,.688,0l1.969-1.281,2.344.125a.615.615,0,0,0,.594-.344l1.063-2.094,2.094-1.062a.67.67,0,0,0,.344-.594l-.125-2.344,1.281-1.969a.677.677,0,0,0,0-.687Zm-1.156,4.094a.564.564,0,0,0-.094.375l.125,2.156-1.906.969a.613.613,0,0,0-.281.281l-.969,1.906-2.125-.125a.742.742,0,0,0-.375.094L28,36.625l-1.781-1.187a.63.63,0,0,0-.344-.094h-.031l-2.156.125-.969-1.906a.613.613,0,0,0-.281-.281l-1.906-.969.125-2.156a.742.742,0,0,0-.094-.375L19.375,28l1.188-1.781a.564.564,0,0,0,.094-.375l-.125-2.156,1.906-.969a.613.613,0,0,0,.281-.281l.969-1.906,2.156.125a.742.742,0,0,0,.375-.094L28,19.375l1.781,1.188a.564.564,0,0,0,.375.094l2.156-.125.969,1.906a.613.613,0,0,0,.281.281l1.906.969-.125,2.125a.742.742,0,0,0,.094.375l1.188,1.781Z" />
                <path class="b"
                  d="M37.112,38.525A2.313,2.313,0,1,0,34.8,36.212,2.307,2.307,0,0,0,37.112,38.525Zm0-3.406a1.063,1.063,0,1,1-1.062,1.063A1.054,1.054,0,0,1,37.112,35.119Z"
                  transform="translate(-11.55 -10.931)" />
                <path class="b"
                  d="M52.712,51.3a2.313,2.313,0,1,0,2.313,2.313A2.307,2.307,0,0,0,52.712,51.3Zm0,3.406a1.063,1.063,0,1,1,1.063-1.062A1.054,1.054,0,0,1,52.712,54.706Z"
                  transform="translate(-22.275 -22.894)" />
                <rect class="b" width="11.344" height="1.25" transform="translate(23.558 31.558) rotate(-45)" />
              </g>
            </g>
          </svg>
        </a><span>Offers</span>
      </li>
      <li class="nav-item item toggle-menu"  routerLink="/user/history" routerLinkActive="active">
        <a href="javascript:void(0);"  class="item">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.4" height="19.4" viewBox="0 0 19.4 19.4">
            <defs>
              <style>
                .c {
                  fill: none;
                  stroke: #bababa;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1.4px;
                }
              </style>
            </defs>
            <g transform="translate(-1.3 -1.3)">
              <circle class="c" cx="9" cy="9" r="9" transform="translate(2 2)" />
              <path class="c" d="M12,6v6l4,2" transform="translate(-1 1)" />
            </g>
          </svg>
        </a><span>History</span>
      </li>    
      <li class="nav-item item" (click)="logout()" >
        <a href="javascript:void(0);"  class="item">
          <svg fill="red" xmlns="http://www.w3.org/2000/svg" width="24.4" height="24" viewBox="0 0 24.4 24"><defs><style>.lga{fill:rgba(110,110,110,0);}.lgb{fill:red;stroke:red;stroke-width:0.4px;}</style></defs><g transform="translate(-15.8 -229)"><rect class="lga" width="24" height="24" transform="translate(16 229)"/><g transform="translate(16 231)"><path class="lgb" d="M52,39.054H36.333a.332.332,0,1,0,0,.665H52l-4.367,4.356a.321.321,0,0,0,0,.465.323.323,0,0,0,.467,0l4.933-4.921v-.465L48.1,34.2a.33.33,0,0,0-.467.465Z" transform="translate(-29.033 -29.733)"/><path class="lgb" d="M16.7,40.307H29.3a1.706,1.706,0,0,0,1.7-1.7v-3.3a.333.333,0,0,0-.667,0v3.3A1.038,1.038,0,0,1,29.3,39.641H16.7a1.038,1.038,0,0,1-1.033-1.032V22.7A1.038,1.038,0,0,1,16.7,21.666H29.3A1.038,1.038,0,0,1,30.333,22.7v3.3a.333.333,0,0,0,.667,0V22.7A1.706,1.706,0,0,0,29.3,21H16.7A1.706,1.706,0,0,0,15,22.7V38.609A1.706,1.706,0,0,0,16.7,40.307Z" transform="translate(-15 -21)"/></g></g></svg>  
        </a><span>Logout</span>
      </li>
    </ul>
  </div>
</div>