<div class="dashboard p-0 m-0">
    <div class="row text-center pt-3 row-margin-0">
        <div class="col-12 text-center p-0 m-0">
            <span style="font-family: 'Poppins ';font-size: 18px;color:#FFFFFF">Hi <span *ngIf="temp_storage.name!='undefined'">{{temp_storage.name}}</span>!</span>
            <!-- <span *ngIf="temp_storage.name=='undefined'" style="font-family: 'Poppins ';font-size: 18px;color:#FFFFFF">Hi {{temp_storage.phone}}!</span> -->
        </div>
    </div>
    <div class="row row-margin-0 text-center m-0 p-0">
        <div class="col-12 text-center">
            <span style="font-family: 'Poppins ';font-size: 18px;color:#F2BEFF;">It’s so nice to see you again.</span>
        </div>
        <div class="col-12 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="265" height="1" viewBox="0 0 265 1">
                <defs>
                    <style>
                        .a {
                            fill: #f2beff;
                        }
                    </style>
                </defs>
                <rect class="a" width="265" height="1" />
            </svg>
        </div>
    </div>
    <div class="row row-margin-0 text-center pt-3 m-0">
        <div class="col-12 ">
            <div class="rec">
                <img class="fw-2x" src="../../assets/img/fw-2x.png">
                <img class="fw-x" src="../../assets/img/fw-x.png">
                <img class="fw-blur" src="../../assets/img/fw-blur.png">
                <img class="coins" src="../../assets/img/coins.png">

                <div class="row row-margin-0">
                    <div class="col-12 text-center showbalance balance">
                        <span class="balancedigit"><strong>{{total?total:"0"}}</strong></span>
                        <div class="balanceword">
                        Point Balance
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
