import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import * as $ from "jquery";
import { TempStorage } from './../temp_storage';
import { ApiService } from "src/app/api-service/api-service.service";
import { CompressImageService } from "src/app/compress-image-service";
import { take } from 'rxjs/operators';
import { HelperService } from "src/app/helper.service";
import firebase from 'firebase';
import { Observable } from "rxjs";
declare global {
  interface Window { recaptchaVerifier: any; confirmationResult: any; recaptchaWidgetId: any }
}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})

export class HomePageComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInputRef: any;
  roleId;
  otp_time = 40;
  interval = null;
  prodCode = null;
  currentUserRole: any = localStorage.getItem("role_id");
  login_bool: number = 1;
  verify_bool: number = 1;
  latitude: any = null;
  longitude: any = null;
  loginForm: FormGroup;
  countryArray: any = [];
  cityArray: any = [];
  stateArray: any = [];
  state_name: any = "";
  city_name: any = "";
  is_already_registered: boolean = null;
  submitted: boolean = false;
  loader: boolean = false;
  captchLogin: boolean = false;
  isGPSEnabled: boolean = false;

  isOTPOpen: boolean = false;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    containerStyles: {
      'display': 'block',
      'padding': '10px'
    },
    inputStyles: {
      'width': '30px',
      'height': '30px'
    }
  };

  months = [
    { name: "Jan", val: 1 },
    { name: "Fab", val: 2 },
    { name: "Mar", val: 3 },
    { name: "Apr", val: 4 },
    { name: "May", val: 5 },
    { name: "Jun", val: 6 },
    { name: "Jul", val: 7 },
    { name: "Aug", val: 8 },
    { name: "Sep", val: 9 },
    { name: "Oct", val: 10 },
    { name: "Nov", val: 11 },
    { name: "Dec", val: 12 },
  ];

  years = [];


  constructor(
    private compressImage: CompressImageService,
    private router: Router,
    public helper: HelperService,
    public temp_storage: TempStorage,
    activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private apiService: ApiService) {
    this.temp_storage.isWhiteBackground = false;

    activatedRoute.queryParams.subscribe((params) => {
      if (params["c"]) {
        this.prodCode = params["c"];
      }
      console.log(this.prodCode);
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    })

    for (let i = 1970; i <= new Date().getFullYear(); i++) {
      this.years.push(i);
    }

    // this.roleId = localStorage.getItem("role_id");     
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      phone: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      country_code: ["+91", [Validators.required]],
      //country: ["101", Validators.required],            
      //state: ["", Validators.required],
      //city: ["", Validators.required],            
      //pincode: ["", Validators.required],
      dobMonth: [1],
      dobYear: [1970],
      user_type: ["0"],
      gender: ["", Validators.required],
      first_name: ["", Validators.required],
      last_name: [""],
    });

    this.gps();
  }

  get f() {
    return this.loginForm.controls;
  }

  gps() {
    // return Observable.create(observer => {
    //   window.navigator.geolocation.getCurrentPosition(position => {
    //     observer.next(position);
    //     observer.complete();
    //   },
    //     error => observer.error(error));
    // });
    if (navigator.geolocation) {
      //return Observable.create(observer => {

        navigator.geolocation.getCurrentPosition((Position) => {
          this.isGPSEnabled = true;
          this.showPosition(Position);
          firebase.auth().useDeviceLanguage();
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            //'size': 'invisible',
            'callback': (response) => {
              this.captchLogin = true;
              // reCAPTCHA solved, allow signInWithPhoneNumber.
            },
            'expired-callback': function () {
              // Response expired. Ask user to solve reCAPTCHA again.
              this.captchLogin = false;
            }
          });
          this.recaptchaRender();
          //this.countryList();
          setTimeout(() => {
            if (this.prodCode) {
              $('#direct_login').click();
            }
          }, 2000);
        }, (err) => {
          console.log(err);
          if (err.code == 1) {
            this.isGPSEnabled = false;
            //observer.error(err)
            // this.helper.toastr.error('Please allow location and refresh your page');
          }
        });
      //});
    }
    else {
      this.helper.toastr.error("Geolocation is not supported by this browser.");
    }
  }


  showPosition(position) {
    console.log(position);
    if (position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    } else {
      this.helper.toastr.error('Please allow location and refresh your page');
    }
  }

  countryList() {
    this.apiService.get('country?parm=all', false).subscribe((response: any) => {

      if (response.status == 200 && response.body.success == 1) {
        this.countryArray = response.body.data;
      } else {


      }
    })
  }
  pinChange() {
    if (this.loginForm.value.country != "" && this.loginForm.value.pincode.length > 3) {
      var country_code = '';
      var index = this.countryArray.findIndex(x => x.id == this.loginForm.value.country);
      if (index != -1) {
        country_code = this.countryArray[index].iso2
      }
      this.apiService.login_get('getCityByPincode/?pincode=' + this.loginForm.value.pincode + '&country_code=' + country_code + '&country_id=' + this.loginForm.value.country).subscribe((response: any) => {

        if (response.status == 200 && response.body.success == 1) {
          this.city_name = response.body.city_detail.city_name;
          this.state_name = response.body.state_detail.state_name;
          this.loginForm.patchValue({
            state: String(response.body.state_detail.id),
            city: String(response.body.city_detail.id)
          });
        } else {
          this.city_name = "";
          this.state_name = "";
          this.loginForm.patchValue({
            state: "",
            city: ""
          });
          this.helper.toastr.error("Invalid Pincode");
        }
      },
        (error) => {
          this.loader = false;
          console.log(error);
        });

    } else {
      if (this.loginForm.value.pincode.length <= 3 && this.loginForm.value.pincode.length != 0) {
        this.helper.toastr.error("Invalid Pincode");
      }
    }
  }

  stateList() {
    this.cityArray = [];
    this.apiService.get('state/' + this.loginForm.value.country + '?parm=all', false).subscribe((response: any) => {

      if (response.status == 200 && response.body.success == 1) {
        this.stateArray = response.body.data;
      } else {
        this.stateArray = [];
      }
    })
  }
  cityList() {
    this.apiService.get('city/' + this.loginForm.value.state + '?parm=all', false).subscribe((response: any) => {

      if (response.status == 200 && response.body.success == 1) {
        this.cityArray = response.body.data;
      } else {
        this.cityArray = [];
      }
    })
  }
  recaptchaRender() {

    const recaptchaVerifier = window.recaptchaVerifier;
    // [START auth_phone_recaptcha_render]
    recaptchaVerifier.render().then((widgetId) => {
      $('#rc-anchor-container').css('width', '249px !important')
      window.recaptchaWidgetId = widgetId;
    });
    // [END auth_phone_recaptcha_render]
  }

  onOtpChange(e) {
    if (e.length == 6) {
      this.redirect_product(e);
    }
  }

  getcustomerDetail() {
    this.loader = true;
    this.apiService.login_get('getCustomerDetail?phone=' + this.loginForm.value.phone + '&country_code=' + this.loginForm.value.country_code).subscribe((response: any) => {
      this.loader = false;
      console.log(response);
      if (response.status == 200 && response.body.success == 1) {
        this.is_already_registered = true;
        this.otp_verification();
      } else {
        this.is_already_registered = false;

      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  register_customer(submitted) {
    if (this.is_already_registered == false) {
      this.submitted = submitted;
      if (this.loginForm.invalid) {
        return;
      }
    } else {
      this.submitted = false;
    }
    this.otp_verification();
    this.loader = true;
    console.log(this.loginForm.value);
    this.apiService.post('addCustomer', this.loginForm.value, null).subscribe((response: any) => {
      this.loader = false;
      console.log(response);
      if (response.status == 200 && response.body.success == 1) {
        this.temp_storage.is_login = true;
        $('#otp_close').click();
        this.temp_storage.first_name = response.body.data.first_name;
        this.temp_storage.last_name = response.body.data.last_name;
        localStorage.setItem('customer_id', response.body.data.id);
        localStorage.setItem('customer_phone', response.body.data.cellnumber);
        localStorage.setItem('country_code', response.body.data.country_code);
        localStorage.setItem('first_name', response.body.data.first_name);
        localStorage.setItem('last_name', response.body.data.last_name);
        // localStorage.setItem('email', response.body.data.email);
        localStorage.setItem('role_id', (response.body.data.role_id) ? response.body.data.role_id : 0);

        firebase.auth().currentUser.getIdToken(true).then((idToken) => {

          localStorage.setItem('access_token', idToken);
          if (this.prodCode) {
            this.temp_storage.is_detail_product = true;
            this.router.navigate(["user/product/" + this.prodCode]);
          } else {
            this.router.navigate(["dashboard"]);
          }

        }).catch((error) => {
          console.log(error);
        })
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }
  loginPopup() {
    this.is_already_registered = null;
    this.loginForm.patchValue({
      first_name: '',
      last_name: '',
      phone: '',
      country_code: "+91",
      user_type: 0,
    })
    this.login_bool = 2;
    this.captchLogin = false;
    grecaptcha.reset(window.recaptchaWidgetId);
    var scale;
    //var scale = $(window).width() * 0.00267;
    console.log($(window).width());
    if ($(window).width() <= 320) {
      scale = $(window).width() * 0.0026;
    } else if ($(window).width() <= 360) {
      scale = $(window).width() * 0.00267;
    } else if ($(window).width() <= 375) {
      scale = $(window).width() * 0.0027;
    } else if ($(window).width() <= 411) {
      scale = $(window).width() * 0.00275;
    } else if ($(window).width() <= 575) {
      scale = $(window).width() * 0.00275;
    } else {
      scale = 0.82;
    }
    setTimeout(() => {

      $('#recaptcha-container div:first div:first').css('-webkit-transform', 'scale(' + scale + ')');
      $('#recaptcha-container div:first div:first').css('transform', 'scale(' + scale + ')');
      $('#recaptcha-container div:first div:first').css('-webkit-transform-origin', '0 0');
      $('#recaptcha-container div:first div:first').css('transform-origin', '0 0');
    }, 100)
    setTimeout(() => {

      $('#recaptcha-container div:first div:first').css('-webkit-transform', 'scale(' + scale + ')');
      $('#recaptcha-container div:first div:first').css('transform', 'scale(' + scale + ')');
      $('#recaptcha-container div:first div:first').css('-webkit-transform-origin', '0 0');
      $('#recaptcha-container div:first div:first').css('transform-origin', '0 0');
    }, 300)
  }
  login() {
    this.submitted = false;
    // this.loginForm.reset();
    if (this.latitude && this.longitude) {
      if (this.loginForm.value.phone.toString().length == 10) {
        if (this.captchLogin == true) {
          this.getcustomerDetail();
        } else {
          this.helper.toastr.error('Please verify captcha');
        }
      } else {
        this.helper.toastr.error('Please Enter Valid phone number');
      }
    } else {
      this.helper.toastr.error('Please Allow Location and Refresh your page');
    }
  }

  resend_otp_verification() {
    $('#otp_close').click();
    $('#direct_login').click();
    this.loginPopup();
  }

  otp_verification() {
    this.loader = true;
    const appVerifier = window.recaptchaVerifier;
    this.otp_time = 40;
    firebase.auth().signInWithPhoneNumber(this.loginForm.value.country_code + this.loginForm.value.phone, appVerifier).then((confirmationResult) => {
      this.loader = false;
      this.interval = setInterval(() => {
        this.otp_time = this.otp_time - 1;
        if (this.otp_time == 0) {
          if (this.interval) {
            clearInterval(this.interval);
          }
        }
      }, 1000)
      // SMS sent. Prompt user to type the code from the message, then sign the
      window.confirmationResult = confirmationResult;
      // this.ngOtpInputRef.setValue("");
      // $('#otp_open').click();
      this.isOTPOpen = true;
    }).catch((error) => {
      this.loader = false;
      this.helper.toastr.error(error.message);
    });
    $('#login_close').click();
  }

  redirect_product(e) {
    this.loader = true;
    window.confirmationResult.confirm(e).then((result) => {
      this.loader = false;
      // User signed in successfully.
      if (result.user) {
        const user = result.user;
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
          localStorage.setItem('access_token', idToken);
          this.register_customer(false);
        }).catch((error) => {
          console.log(error);
        })
      } else {
        this.helper.toastr.error(' Invalid OTP');
      }
      // ...
    }).catch((error) => {
      this.loader = false;
      console.log(error);
      this.helper.toastr.error(' Invalid OTP');
      // User couldn't sign in (bad verification code?)
      // ...
    });

  }

  number_validation(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      /*if (charCode == 46) {
          return true;
      }
      else {*/
      return false;
      // }
    }
    return true;
  }
}
