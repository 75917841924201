import { Component } from '@angular/core';
import { Router, Event, /*RouterEvent,*/ NavigationStart, NavigationEnd, NavigationError,ActivatedRoute, NavigationCancel } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {filter, map, mergeMap} from 'rxjs/operators';
import firebase from 'firebase';
import {TempStorage} from './temp_storage';
import {HelperService} from './helper.service';
declare var jQuery:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent {

  title = 'trusttags-admin';
  loading:boolean =false;
  interval:any = null;
  constructor(    
                private router: Router,
                public helper: HelperService,
                private route: ActivatedRoute,
                private titleService: Title,
                public temp_storage:TempStorage 
                ) 
  {
  	firebase.initializeApp(this.temp_storage.firebase);
     /*router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });*/
  }

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) =>{
      if (user) {
        if(localStorage.getItem('first_name')){
          this.temp_storage.is_login=true;
          this.temp_storage.first_name= localStorage.getItem('first_name');
          this.temp_storage.last_name= localStorage.getItem('last_name');
            console.log("idToken");
          firebase.auth().currentUser.getIdToken(true).then((idToken)=>{
            console.log(idToken);
            localStorage.setItem('access_token',idToken);
          }).catch((error)=>{
            console.log(error);
          })
          this.interval = setInterval(()=>{
            firebase.auth().currentUser.getIdToken(true).then((idToken)=>{
              console.log(idToken);
              localStorage.setItem('access_token',idToken);
            }).catch((error)=>{
              console.log(error);
            })
          },240000);
        }else{
          if(this.interval){
            clearInterval(this.interval)
          }
        }
      } else {
        localStorage.clear();
        this.temp_storage.is_login=false;
        this.router.navigate(["/"]);
        console.log("No user is signed in.");
      }
    });
    
  	
    /*    
         this.updateDescription(event['description'], event['keywords'], event['title']);

        this.router.events
        .filter((event) => event instanceof NavigationEnd)
        .map(() => this.route)
        .map((route) => {
            while (route.firstChild) route = route.firstChild;
            return route;
        })
        .filter((route) => route.outlet === 'primary')
        .mergeMap((route) => route.data)
        .subscribe((event) => {
            this.updateDescription(event['description'], event['keywords'], event['title']);
        });*/
    



  }


  updateDescription(desc: string, keywords: string, title: string) {
    this.titleService.setTitle(title);
  }

  /*navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => { // here
        this.loading = false;
      }, 500);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        this.loading = false;
      }, 500);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
        this.loading = false;
      }, 500);
    }
  }*/
}
