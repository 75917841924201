import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    Route,
    ActivatedRoute,
} from "@angular/router";
import { Observable } from "rxjs";
import 'rxjs/add/operator/do';
import { TempStorage } from "../temp_storage";

@Injectable()
export class AuthGuard implements CanActivate, HttpInterceptor {
    constructor(private router: Router, private _router: Router, public tempStorage: TempStorage) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    localStorage.clear();
                    this.router.navigate(["/"]);
                    // redirect to the login route
                    // or show a modal
                }
            }
        });
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // console.log("id::", next.queryParams.id);

        let currentUserId = localStorage.getItem("customer_id");
        if (currentUserId == null) {
            if (state.url == "" || state.url == "/" || state.url.includes("/?c=")) {
                return true;
            } else {
                this.tempStorage.is_login = false;
                this.router.navigate(["/"]);
            }
        } else {
            if (this.checkGSPStatus()) {
                if (state.url.includes("/?c=")) {
                    this.tempStorage.is_detail_product = true;
                    this.router.navigate(["user/product/" + state.url.split("?c=")[1]]);
                } if (state.url == "" || state.url == "/") {
                    this.router.navigate(["/user/history"]);
                } else {
                    return true;
                }
            } else {
                this.tempStorage.is_login = false;
                this.router.navigate(['/']);
            }
        }
        /*if (currentUserId == null) {
          this.router.navigate(["/"]);
        } else if (next.data.permissions == false) {
          return true;
        } else {
          if (     
            state.url.toString().split("?")[0] == "./stock-report" &&
            next.queryParams.id == undefined
          ) {
            console.log(true);
            this.router.navigate(["./stock-report/factory-list"]);
          } else {
            return true;
          }
        }*/
    }

    async checkGSPStatus(): Promise<Boolean> {
        try {
            if (navigator.geolocation) {
                let location = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                })
               
                if (location) {
                    return true
                } else {
                    localStorage.clear();
                    return false
                }
            } else {
                localStorage.clear();
                return false;
            } 
        } catch (error) {
            localStorage.clear();
            return false
        }       
    }
}
