import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/api-service/api-service.service";
import { HelperService } from "src/app/helper.service";
import {TempStorage} from './../temp_storage';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  
	type:number = 1;
  verified_list:any = [];
  registered_list:any = null;
  loader:boolean = false;
 
  constructor(private router: Router, private apiService: ApiService,public helper: HelperService,public temp_storage:TempStorage) {
    temp_storage.isWhiteBackground = true;
  }

  ngOnInit() {
    this.get_product_list();
    this.get_product_lists();
  }

  get_product_list(){
    this.loader = true;
    this.apiService.get('user/getVerifiedProductList?customer_id='+ localStorage.getItem("customer_id"), false).subscribe((response: any) => {
      this.loader = false;
      if(response.status == 200 && response.body.success == 1){
        this.verified_list = response.body.data;
      }else {
        this.verified_list = [];
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  get_product_lists(){
    this.type = 2;
    this.loader = true;
    this.apiService.get('user/getRegisteredProductList?customer_id='+ localStorage.getItem("customer_id"), false).subscribe((response: any) => {
      this.loader = false;
      if(response.status == 200 && response.body.success == 1){
        this.registered_list = response.body.data;
      }else {
        this.registered_list = [];
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  redirect_product(code){
    this.router.navigate(["/user/product/"+ code],{queryParams: {detail:1}});
  }

}
