import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TempStorage } from "src/app/temp_storage";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})

export class SidebarComponent implements OnInit {

  roleId;
  sidebar_width;
  currentUserRole: any = localStorage.getItem("role_id");  

  constructor(private router:Router,public temp_storage:TempStorage) {
    // this.roleId = localStorage.getItem("role_id");     
   }
 
   ngOnInit() {
         $('#left-menu-2').on('click', function () {
         $('body').toggleClass('sidebar-left-close');
     });
 
     $('.toggle-menu').on('click', function () {
       $('body').toggleClass('sidebar-left-close');
   });
   }
 
   logout(){
     localStorage.clear();
     // this.router.navigate(['/']);
     window.location.reload() 
   }
 
   sidebar(){
     console.log("sidebar click")
     document.getElementById('sb').style.width = "0"
   }
}
