<div class="loader_wrapper" *ngIf="loader"><div class="batch_loader" ></div></div>

<!-- <div class="container-fluid bg-light-opac">
    <div class="row">
        <div class="container-fluid my-3 main-container">
            <div class="row align-items-center">
                <div class="col">
                    <h2 class="content-color-primary page-title">Your Purchases</h2>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="my-card mb-4 fullscreen" *ngIf="registered_list != null">   
   
    <div *ngIf="registered_list.length == 0" class="mt-5 text-center">
        <br><br><br>
        <img src="../../assets/img/M.A.fhf.png" style="width: 90px !important; height: auto !important;" alt="">
        <h5 class="mt-4" style="color: #b3b3b3;" >You have not Registered any Product.</h5>
    </div>
    <div class="card-body pt-0" *ngIf="registered_list.length != 0">
        <table class="table border-bottom mb-0">
            <thead class="d-none">
                <tr>
                    <th>Business</th>
                    <th data-breakpoints="xs">Total</th>
                    <th data-breakpoints="xs sm">Status</th>
                    <th data-breakpoints="xs sm">Price</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let registered of registered_list" class="bg-white">
                    <td>
                        <div class="media">
                            <div class="icon-circle icon-50 bg-light-primary mr-3">
                                <img src="{{helper.image_url +'product/'+ registered.product.main_image}}" class="icon-image" alt="" srcset="">
                            </div>
                            <div class="media-body">
                                <h6 class="my-0 mt-1">{{registered.product.name}}</h6>
                                <p class="small">registered on {{registered.createdAt | date: "dd MMM, yyyy"}}</p>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">
                        <span class="btn btn-rounded btn-outline-success px-3 btn-sm" (click)="redirect_product(registered.code)">Detail</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="blank-footer"></div>
</div>       
        