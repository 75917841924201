import { Component, OnInit, ElementRef, ViewChild, Renderer2, ViewEncapsulation } from '@angular/core';
import { ApiService } from "src/app/api-service/api-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { HelperService } from "src/app/helper.service";
import { Globals } from '../global';
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js';
import { TempStorage } from '../temp_storage';
import * as $ from "jquery";

@Component({
  selector: 'app-wallet_point',
  templateUrl: './wallet_point.component.html',
  styleUrls: ['./wallet_point.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WalletPointComponent implements OnInit {

  p1 = this.globals.p;
  p2 = this.globals.p;
  p3 = this.globals.p;

  currentLimit1 = this.globals.currentLimit;
  currentLimit2 = this.globals.currentLimit;
  currentLimit3 = this.globals.currentLimit;

  pageLimits1 = this.globals.pageLimits;
  pageLimits2 = this.globals.pageLimits;
  pageLimits3 = this.globals.pageLimits;

  type: number = 1;
  earning_list: any = [];
  reward_list: any = [];
  reward_history_list: any = [];
  code: any = null;
  reward_points: number = 0;
  main_image: any = null;
  reward_id;
  card_id;
  point: number = 0;
  totalRewardStock: number = 0;
  redeemForm: FormGroup;
  redeemLuckyForm: FormGroup;
  luckyRedeemSubmitted = false;
  loader: boolean = false;
  submitted: boolean = false;
  reward: String = '0';
  consumer_id;
  open_reward = false;
  open_point = false;
  scratch_cards;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    containerStyles: {
      'display': 'block',
      'padding': '10px'
    },
    inputStyles: {
      'width': '30px',
      'height': '30px'
    }
  };
  qr_code;
  constructor(private route: ActivatedRoute, private renderer: Renderer2, private router: Router, private globals: Globals, private apiService: ApiService, public helper: HelperService, private formBuilder: FormBuilder, public temp_storage: TempStorage) {
    this.temp_storage.isWhiteBackground = true;
    console.log(temp_storage)
    this.qr_code = this.route.snapshot.queryParams['qr_code'];
    console.log("QQ", this.qr_code, this.card_id);
    if (this.qr_code != undefined) {
      // this.scanQr(this.qr_code)
    }
  }

  ngOnInit() {
    this.consumer_id = localStorage.getItem('customer_id');

    this.redeemForm = this.formBuilder.group({
      phone: ["", [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      country_code: ["+91", [Validators.required]],
      pincode: ["", [Validators.required,Validators.maxLength(9)]],
      first_name: ["", [Validators.required,Validators.maxLength(39)]],
      // email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      address: ["", [Validators.required, Validators.maxLength(39)]],
    });

    this.redeemLuckyForm = this.formBuilder.group({
      first_name: ["", [Validators.required,Validators.maxLength(39)]],
      phone: ["", [Validators.required, Validators.maxLength(13), Validators.minLength(10)]],
      country_code: ["+91", [Validators.required]],
      address: ["", [Validators.required, Validators.maxLength(39)]],
      pincode: ["", [Validators.required,Validators.maxLength(9)]],
      // email: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });

    this.getEarningList();
    this.getRewardTotal();
    this.getredeemlist();
    this.getRewardList();
    this.getScratchCards();
  }

  // scanQr(qr_code) {
  //   this.apiService.post('user/add-scratch-cards', {
  //     consumer_id: localStorage.getItem('consumer_id'),
  //     qr_code: qr_code,
  //   }, null).subscribe((response: any) => {
  //     console.log("scanqr res", response.body);
  //     if (response.status == 200 && response.body.success == 1) {
  //       this.getScratchCards();
  //     }
  //   },
  //     (error) => {
  //       this.loader = false;
  //       console.log(error);
  //     })
  // }
  get f() {
    return this.redeemForm.controls;
  }

  getRewardTotal() {
    this.apiService.get('user/getRewardPointTotal?customer_id=' + localStorage.getItem("customer_id"), false).subscribe((response: any) => {
      this.loader = false;
      console.log("total resp", response);

      if (response.status == 200 && response.body.success == 1) {
        this.helper.reward_total = response.body.total;
        this.helper.payTmAmountTotal = response.body.payTmPoint;
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  getredeemlist() {
    this.loader = true;
    var customer_id = localStorage.getItem('customer_id');
    this.apiService.get('user/rewardhistory?consumer_id=' + localStorage.getItem("customer_id"), false).subscribe((response: any) => {
      this.loader = false;

      if (response.status == 200 && response.body.success == 1) {
        this.reward_history_list = response.body.data;
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  getScratchCards() {
    this.apiService.get('user/scratch-cards?consumer_id=' + localStorage.getItem("customer_id"), false).subscribe((response: any) => {
      this.loader = false;

      if (response.status == 200 && response.body.success == 1) {
        console.log("scratch...", response.body);
        this.scratch_cards = response.body.data;
        // this.getEarningList();
        // this.earning_list = this.earning_list.concat(this.scratch_cards);
        console.log("earning", this.earning_list);

      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  redeemRewardOpen(reward_id, reward) {
    console.log("reward redeem", reward);

    this.apiService.get('user/user-address?consumer_id=' + this.consumer_id, null).subscribe((response: any) => {
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        console.log("address response", response.body);

        this.redeemForm.patchValue({
          phone: response.body.data.cellnumber,
          country_code: "+91",
          pincode: response.body.data.pincode,
          first_name: response.body.data.first_name,
          // email: "",
          address: "",
        })
      } else {
        this.helper.toastr.error('Address Not Found');
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })

    console.log("reward.points", reward.reward_points);
    $('#want_redeeem_open').click();

    if ((reward.reward_points - this.helper.reward_total) <= 0) {
      this.main_image = reward.main_image;
      console.log(this.main_image);
      this.point = reward.reward_points;
      $('#want_redeeem_open').click();
    }
    this.submitted = false;
    this.reward_id = reward_id;
  }

  redeemRewards() {
    console.log("redeem rewards ", this.redeemForm);
    this.submitted = true;
    if (this.redeemForm.invalid) {
      return;
    }
    this.loader = true;
    this.apiService.post('user/rewardhistory', {
      reward_id: this.reward_id,
      data: this.redeemForm.value
    }, null).subscribe((response: any) => {
      $('#want_redeem_close').click();
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.helper.toastr.success('Reward Redeem Successfully');
        this.getRewardTotal();
        this.getredeemlist();
      } else {
        this.helper.toastr.error('Reward Redeem Failed');
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  getRewardList() {
    this.loader = true;
    this.apiService.get('user/consumer-rewards', false).subscribe((response: any) => {
      this.loader = false;
      console.log("reward res", response.body.data);

      if (response.status == 200 && response.body.success == 1) {
        this.reward_list = response.body.data.filter((item) => {
          return (item.stock - item.reward_stock) > 0
        });
        // response.body.data.forEach((a: any) => {
        //   // if((a.reward_stock - a.stock) >0){
        //   this.reward_list.push(a);
        //   // } 
        // })

      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  getEarningList() {
    this.loader = true;
    this.apiService.get('user/getRewards?customer_id='+localStorage.getItem('customer_id'), false).subscribe((response: any) => {
      this.loader = false;
      console.log("get rewards earnin", response.body);

      if (response.status == 200 && response.body.success == 1) {
        this.earning_list = response.body.customer_reward;
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  open_point_scratch_card(id, point, code) {
    this.reward_id = id;
    this.point = point;
    this.code = code;
    this.open_point = true;
    console.log("point scratch card");

    this.createNewPointScratchCard();
  }

  open_reward_scratch_card(card_id, reward, card_type) {
    this.reward = reward;
    this.card_id = card_id;
    this.code = card_type;
    this.open_reward = true;
    console.log("rewar", card_id, reward, card_type);

    this.createNewPointScratchCard();
  }

  open_scratch_card_1(id, reward) {
    this.card_id = id;
    this.reward = reward;
    // this.createNewScratchCard();
  }


  createNewPointScratchCard() {
    const scContainer = document.getElementById('js--sc--container')
    const sc = new ScratchCard('#js--sc--container', {
      scratchType: SCRATCH_TYPE.CIRCLE,
      containerWidth: 220,//scContainer.offsetWidth,
      containerHeight: 220,
      imageForwardSrc: './assets/img/you-have-reward.jpg',
      //imageBackgroundSrc: './assets/images/scratchcard-background.svg',
      htmlBackground: '',
      clearZoneRadius: 40,
      nPoints: 30,
      pointSize: 4,
      callback: () => {
        console.log("call", this.open_point, this.open_reward);
        // this.updateScratchCard();
        if (this.open_point == true && this.open_reward == false) {
          this.updateReward();
        }
        else if (this.open_point == false && this.open_reward == true) {
          this.updateScratchCard()
        }
        this.getEarningList();
        setTimeout(() => {
          this.scratch_card();
          this.open_point = false;
          this.open_reward = false
        }, 2000)
      }
    })
    // Init
    sc.init().then(() => {
      sc.canvas.style.position = "absolute";
      sc.canvas.style.top = "0px";
      sc.canvas.style.left = "0px";
      sc.canvas.style['z-index'] = "99";
      sc.canvas.style['border-radius'] = "5px";

      $('#card_open').click();
      sc.canvas.addEventListener('scratch.move', () => {
        let percent = sc.getPercent().toFixed(2);
      })
    }).catch((error) => {
      // image not loaded
      alert(error.message);
    });;
  }

  createNewRewardScratchCard() {
    this.open_reward = true;
    const scContainer = document.getElementById('js--sc--container-reward')
    const sc = new ScratchCard('#js--sc--container-reward', {
      scratchType: SCRATCH_TYPE.CIRCLE,
      containerWidth: 220,//scContainer.offsetWidth,
      containerHeight: 220,
      imageForwardSrc: './assets/img/blue-card.jpg',
      //imageBackgroundSrc: './assets/images/scratchcard-background.svg',
      htmlBackground: '',
      clearZoneRadius: 40,
      nPoints: 30,
      pointSize: 4,
      callback: () => {
        console.log("call");
        this.updateScratchCard();
        setTimeout(() => {
          this.scratch_card();
          this.open_reward = false;
        }, 3000)
      }
    })
    // Init
    sc.init().then(() => {
      sc.canvas.style.position = "absolute";
      sc.canvas.style.top = "0px";
      sc.canvas.style.left = "0px";
      sc.canvas.style['z-index'] = "99";
      sc.canvas.style['border-radius'] = "5px";

      $('#card_open_reward').click();
      sc.canvas.addEventListener('scratch.move', () => {
        let percent = sc.getPercent().toFixed(2);
      })
    }).catch((error) => {
      // image not loaded
      alert(error.message);
    });;
  }
  redeemLuckyRewards() {
    // this.redeemLuckyRewardOpen(card_id)
    console.log("ccccc", this.consumer_id, this.redeemLuckyForm.value, this.card_id);
    console.log("ccard", this.card_id);
    this.luckyRedeemSubmitted = true;
    if (this.redeemLuckyForm.invalid) {
      return;
    }
    this.apiService.post('user/lucky-rewardredeem', {
      card_id: this.card_id,
      data: this.redeemLuckyForm.value
    }, null).subscribe((response: any) => {
      $('#want_lucky_redeem_close').click();
      this.loader = false;
      this.luckyRedeemSubmitted = false;
      console.log("ereddem res", response.body);

      if (response.status == 200 && response.body.success == 1) {
        this.helper.toastr.success('Reward Redeem Successfully');
        this.getEarningList()
      } else {
        this.luckyRedeemSubmitted = false;
        this.helper.toastr.error('Reward Redeem Failed');
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })

  }
  redeemLuckyRewardOpen(card_id, reward) {
    this.reward = reward;
    console.log("reward redeem", card_id);
    this.card_id = card_id;
    this.apiService.get('user/user-address', null).subscribe((response: any) => {
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        console.log("address response", response.body);

        this.redeemLuckyForm.patchValue({
          phone: response.body.data.cellnumber,
          country_code: "+91",
          pincode: response.body.data.pincode,
          first_name: response.body.data.first_name,
          // email: "",
          address: response.body.data.address,
        })
      } else {
        this.helper.toastr.error('Address Not Found');
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
    $('#want_lucky_redeeem').click();

    this.submitted = false;
  }

  updateScratchCard() {
    this.loader = true;
    this.apiService.put('user/update-scratchcard', {
      card_id: this.card_id,
    }, false).subscribe((response: any) => {
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.getEarningList();
        //  this.getEarningList();
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  updateReward() {
    this.loader = true;
    this.apiService.put('user/updateRewards', {
      reward_id: this.reward_id,
      code: this.code
    }, false).subscribe((response: any) => {
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.helper.reward_total = Number(this.helper.reward_total) + Number(this.point);
        this.getEarningList();
      }
    },
      (error) => {
        this.loader = false;
        console.log(error);
      })
  }

  scratch_card() {
    $('#card_close').click();

    var index = this.earning_list.findIndex((x) => x.id == this.reward_id);
    if (index == -1) {
      this.getEarningList();
    } else {
      this.earning_list[index].is_scanned = true;
    }
    this.router.navigate(["user/wallet_point"]);
  }

  number_validation(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      /*if (charCode == 46) {
          return true;
      }
      else {*/
      return false;
      // }
    }
    return true;
  }

  get f1() {
    return this.redeemForm.controls;
  }
  get f2() {
    return this.redeemLuckyForm.controls;
  }

}
