import { Component, OnInit, ElementRef, ViewChild, Renderer2, ViewEncapsulation } from '@angular/core';
import { QrScannerComponent } from 'angular2-qrscanner';
import { ApiService } from "src/app/api-service/api-service.service";
import { HelperService } from "src/app/helper.service";
import { Router } from '@angular/router';
import { TempStorage } from '../temp_storage';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'app-earn-points',
  templateUrl: './earn-points.component.html',
  styleUrls: ['./earn-points.component.css']
})
export class EarnPointsComponent implements OnInit {
  @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent;
  qrcode;
  loader:boolean = false;
  constructor(private router: Router, public temp_storage: TempStorage, private apiService: ApiService,public helper: HelperService) { }
  
  ngOnInit(): void {
    this.temp_storage.isWhiteBackground = false;
  }


  ngAfterViewInit(): void {
    this.qrScannerComponent.getMediaDevices().then(devices => {
      const videoDevices: MediaDeviceInfo[] = [];
      for (const device of devices) {
        if (device.kind.toString() === 'videoinput') {
          videoDevices.push(device);
        }
      }
      if (videoDevices.length > 0) {
        let choosenDev;
        for (const dev of videoDevices) {
          if (dev.label.includes('back')) {
            choosenDev = dev;
            break;
          }
        }
        if (choosenDev) {
          this.qrScannerComponent.chooseCamera.next(choosenDev);
        } else {
        }
      }
          this.qrScannerComponent.chooseCamera.next(videoDevices[1]);
    });

    this.qrScannerComponent.capturedQr.subscribe(result => {
      console.log("captured qr", result);
      let temp = result;
      let arr = temp.split("/");
      let code = arr[arr.length - 1];
        this.checkScannedCodes(code);
    });
  }
  
  ngOnDestroy(){
    this.qrScannerComponent.stopScanning();
  }

  checkScannedCodes(code){
    var customer_id = localStorage.getItem('customer_id');
    this.loader = true;
    this.apiService.get('user/checkScannedCodes?customer_id=' + customer_id + '&shortCode=' + code, false).subscribe((response: any) => {
      this.loader = false;
      if (response.status == 200 && response.body.success == 1) {
        this.router.navigate(['user/product/' + code]);
      }else {
        this.loader = false;
        this.helper.toastr.error(response.body.message);
        setTimeout(() => {
           window.location.reload();
        },1000)
        //this.router.navigate(['user/history']);
      }
    })
  }
  

  onqrRead(event) {
    console.log("qr read", event);

  }

  submit() {
    console.log(this.qrcode);
    if(this.qrcode != undefined || this.qrcode){
      this.checkScannedCodes(this.qrcode);
    }else {
      this.helper.toastr.error("Please scan qrcode or enter qrcode");
      return;
    }
    //this.router.navigate(['user/product/' + this.qrcode])

  }
}
