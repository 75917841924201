  <footer class="border-top cus_padding">
            <div class="row">
                <div class="col-12 col-sm-6">© 2019 All rights reserved by <b>TrustTags</b></div>
              <!--   <div class="col-12 col-sm-6 text-right"><a href="" class="content-color-secondary">Privacy Policy</a> | <a href="" class="content-color-secondary">Terms of use</a></div> -->
            </div>
  </footer>
  <script src="assets/js/jquery-3.2.1.min.js"></script>
        
        <script src="assets/vendor/bootstrap-4.1.3/js/bootstrap.min.js"></script>
       
        <script src="assets/vendor/sparklines/jquery.sparkline.min.js"></script>
        
        <script src="assets/vendor/circle-progress/circle-progress.min.js"></script>
        
        <script src="assets/vendor/swiper/js/swiper.min.js"></script>
        <script src="assets/vendor/chartjs/Chart.bundle.min.js"></script>
        <script src="assets/vendor/chartjs/utils.js"></script>
       
        <script src="assets/vendor/footable-bootstrap/js/footable.min.js"></script>
      
        <script src="assets/vendor/bootstrap-daterangepicker-master/moment.js"></script>
        <script src="assets/vendor/bootstrap-daterangepicker-master/daterangepicker.js"></script>
      
        <script src="assets/vendor/jquery-jvectormap/jquery-jvectormap.js"></script>
        <script src="assets/vendor/jquery-jvectormap/jquery-jvectormap-world-mill-en.js"></script>
      
        <script src="assets/vendor/jquery-jvectormap/jquery-jvectormap.js"></script>
        <script src="assets/vendor/jquery-jvectormap/jquery-jvectormap-in-mill.js"></script>
      
        <script src="assets/vendor/chosen1.8/chosen.jquery.min.js"></script>
        <script src="assets/vendor/cookie/jquery.cookie.js"></script>
     
        <script src="assets/js/main.js"></script> 