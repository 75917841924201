<div class="loader_wrapper" *ngIf="loader">
    <div class="batch_loader"></div>
</div>
<div style="height:110vh;">
<!-- <div class="col-auto" style="float: right;margin: 10px;">
    <a href="javascript:void(0);" routerLink="/user/history"
      class="btn btn-rounded pink-gradient text-uppercase pr-3"><i class="fas fa-angle-left"></i>
      <span class="text-hide-xs"> </span> Back</a>
  </div> -->
<!-- <h6 class="col-auto" style="margin: 10px;">Scan QR Code</h6> -->

<div>
    <div class="camera">
        <div class="inner-frame"></div>
    <qr-scanner [debug]="true" [canvasWidth]="720" [canvasHeight]="1080" [stopAfterScan]="true" [updateTime]="500"
        (onRead)="onqrRead($event)">
    </qr-scanner>
    </div>
    <div class="row mt-3 m-0 p-0">
        <div class="col-12 text-center" style="color:white;font-family:sans-serif;opacity: 50%;">OR</div>
        <hr style="border-top: 1px solid red;">
    </div>
    <hr class="hrline">

    <div class="row mt-3 m-0 p-0">
        <div class="col-12 text-center" style="font-family:sans-serif;width: 400px;">
            <div class="form-group text-center m-0 p-0 " style="width: 300px;display: inline-block;min-height: 105px;">
                <input type="text" class="form-control" id="usr" [(ngModel)]="qrcode" required>
                <label for="usr">Enter Your QR Code</label>

            </div>
            <div> 
                <a href="javascript:void(0);" (click)="submit()" class="btn btn-rounded pink-gradient text-uppercase">
                    <span class="">submit</span></a>
                </div>
        </div>
        <hr style="border-top: 1px solid red;">
    </div>

    
</div>
</div>