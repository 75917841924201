import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL = environment.apiURL;
  header: any;
  
  constructor(private http: HttpClient) { }

  getHeaders(){
    /*this.tokenHeader = {
      observe: "response",
      headers: {        
        "x-access-token": localStorage.getItem("access_token")
      }
    }; */   
        this.header = new HttpHeaders({        
        "x-access-token": localStorage.getItem("access_token")
        });
  }

  google_get(path) {
      return this.http.get(
        path 
      );
  }
  get(path,token = null,isDownloadable = null) {
    this.getHeaders();
   
     if(isDownloadable) {
      this.header.responseType = 'arraybuffer';
    }
    console.log(this.header);
    return this.http.get(
      this.apiURL + path,
      { observe: "response",headers:this.header }
    );
  }

  login_get(path) {
    
    return this.http.get(
      this.apiURL + path,
      { observe: "response"}
    );
  }

  post(path, data, token = null) {
    this.getHeaders();
    data.access_token = localStorage.getItem("access_token");
    return this.http.post(
      this.apiURL + path,
      data,
      { observe: "response",headers:this.header }
    );
  }

  put(path, data, token = null) {
    this.getHeaders();
    data.access_token = localStorage.getItem("access_token");
    return this.http.put(
      this.apiURL + path,
      data,
      { observe: "response",headers:this.header }
    );
  }

  delete(path, token = null) {
    this.getHeaders();

    return this.http.delete(
      this.apiURL + path,
      { observe: "response",headers:this.header }
    );
  }
}
